import React, {useState} from 'react';
import './style.css';
import CloseTipModal from '../../assets/images/CloseTipModal.png';
import Modal from 'react-modal';

function TipModal (props) {


        const [check, setCheck] = useState("");

        const selectItems=(e)=>{
                setCheck(e.target.id);
        }

        const [chooseAmount, setChooseAmount]= useState("");

        const chooseAmountFunction =(data)=>{
                setChooseAmount(data);
        }

        const customStyles = {
                content: {
                        padding: '0px 40px',
                        maxWidth: '448px',
                        width: '100%',
                        height:'419px',
                        top: '50%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        marginRight: '-50%',
                        transform: 'translate(-50%, -50%)',
                },
        };

        // console.log(check);
        return (
        <>
                <Modal
                    isOpen={props.tipModalIsOpen}
                    onRequestClose={props.closeTipModal}
                    // onAfterOpen={props.closeSignUpModal}
                    style={customStyles}
                    ariaHideApp={false}
                    //contentLabel="Login"
                >
            <div className={"TipModalWholeDiv"}>
                    <div className={"TipXDiv"}>
                            <div className={"TipWord"}>TIP</div>
                            <img className={"XTipModal"} src={CloseTipModal} alt={"closeTipModalSign"} onClick={props.closeTipModal} />
                    </div>
                    <div className={"TipModalDiv1"}>Choose currency</div>
                    <div className={"TipModalDiv2"}>
                            <div className={"TipModalDiv3" }>
                                    <input className={"inputBtn"} type="radio" name='tips1' id={"tipsOne"}/>
                                    <label className={"TipModalDiv4"} htmlFor={"tipsOne"}>XRP</label>
                            </div>
                            <div className={"TipModalDiv3"}>
                                    <input className={"inputBtn"} type="radio" name='tips1' id={"tipsTwo"} defaultChecked/>
                                    <label className={"TipModalDiv4"} htmlFor={"tipsTwo"}>XPoems</label>
                            </div>
                    </div>
                    <div className={"TipModalDiv5"}>
                            <button disabled={check ==="two"} onClick={() => {
                                    console.log('not disabled')
                            }} className={`TipModalDiv6  ${check === 'one' && "blueButton"} ${chooseAmount === '1' && "blueInside"} `} onClick={()=>chooseAmountFunction('1')} >1</button>
                            <button disabled={check ==="two"} className={`TipModalDiv6  ${check === 'one' && "blueButton"} ${chooseAmount === '10' && "blueInside"}` }
                                    onClick={()=>chooseAmountFunction('10')}>10</button>
                            <button disabled={check ==="two"} className={`TipModalDiv6  ${check === 'one' && "blueButton"} ${chooseAmount === '100' && "blueInside"}` }
                                    onClick={()=>chooseAmountFunction('100')} >100</button>
                            <button disabled={check ==="two"} className={`TipModalDiv6  ${check === 'one' && "blueButton"} ${chooseAmount === '200' && "blueInside"}` }
                                    onClick={()=>chooseAmountFunction('200')}>200</button>
                    </div>
                    <div className={"TipModalDiv7"}>
                            <button className={`TipModalDiv8 ${check === 'two' && "blueButton"} ${chooseAmount === '1000' && "blueInside"}`} disabled={check ==="one"}
                                    onClick={()=>chooseAmountFunction('1000')}>1000</button>
                            <div className={"TipModalDiv9"}>
                                    <div className={"TipModalDiv3"}>
                                            <input className={"inputBtn"}  type="radio" name='tips2'  onClick={selectItems} id={"one"} defaultChecked/>
                                            <label className={"TipModalDiv4"} htmlFor={"one"}>Selected Tip Amount</label>
                                    </div>
                                    <div className={"TipModalDiv3"}>
                                            <input className={"inputBtn"} type="radio" name='tips2' onClick={selectItems} id={"two"}/>
                                            <label className={"TipModalDiv4"} htmlFor={"two"}>Custom Amount</label>
                                    </div>
                            </div>
                    </div>
                    <div className={"TipModalLastDiv btn"}>Xumm Wallet</div>
            </div>
                </Modal>
            </>
            )
            }


            export default TipModal;
