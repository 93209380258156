import React, {useState} from 'react';
import './style.css';
import Modal from 'react-modal';
import ProfilePictureModalPic1 from "../../assets/images/ProfilePictureModalPic1.png";
import ProfilePictureModalPic2 from "../../assets/images/ProfilePictureModalPic2.png";
import ProfilePictureModalPic3 from "../../assets/images/ProfilePictureModalPic3.png";
import ProfilePictureModalPic4 from "../../assets/images/ProfilePictureModalPic4.png";
import ProfilePictureModalPic5 from "../../assets/images/ProfilePictureModalPic5.png";


function ProfilePictureModal (props){



    const customStyles = {
        content: {
            padding: '40px 40px',
            maxWidth: '600px',
            width: '100%',
            height:'512px',
            top: '50%',
            right: '63%',
            left: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
        },
    };

    return (
        <>
            <Modal
                isOpen={props.profilePictureModalIsOpen}
                onRequestClose={props.closeProfilePictureModal}
                // onAfterOpen={props.closeProfilePictureModal}
                style={customStyles}
                ariaHideApp={false}
            >
                <div className={"ProfilePictureModalDiv1"}>
                    <div className={"ProfilePictureModalDiv2"}>Profile picture</div>
                    <img className={"ProfilePictureModalDiv3"} src={ProfilePictureModalPic2} alt={"closeButtonPic"} onClick={props.closeProfilePictureModal}/>
                </div>
                <div className={"ProfilePictureModalMiddleDiv"}>
                    <img className={"ProfilePictureModalDiv4"}  src={ProfilePictureModalPic1} alt={"profilePicture"} />
                </div>
                < div className={"ProfilePictureModalLastDiv"}>
                    <div className={"ProfilePictureModalLastDivPart"}>
                        <div className={"ProfilePictureModalDiv5"}>
                            <img className={"ProfilePictureModalDiv6"}  src={ProfilePictureModalPic3} alt={"editBtn"} />
                            <div className={"ProfilePictureModalDiv7"}>Edit</div>
                        </div>
                        <div className={"ProfilePictureModalDiv5"} onClick={props.openUpdateProfileModal}>
                            <img className={"ProfilePictureModalDiv6"}  src={ProfilePictureModalPic4} alt={"changePhotoBtn"} />
                            <div className={"ProfilePictureModalDiv7"}>Change photo</div>
                        </div>
                    </div>
                    <div className={"ProfilePictureModalDiv5"}>
                        <img className={"ProfilePictureModalDiv6"}  src={ProfilePictureModalPic5} alt={"trushBtn"} />
                        <div className={"ProfilePictureModalDiv7"}>Delete</div>
                    </div>
                </div>
            </Modal>
        </>
    )
}


export default ProfilePictureModal;