import React, {useState} from 'react';
import './style.css';
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import BuyDownVector from "../../assets/images/BuyDownVector.png";
import BuyUpVector from "../../assets/images/BuyUpVector.png";
import BuyRedirectImg1 from "../../assets/images/BuyRedirectImg1.png";
import BuyRedirectImg2 from "../../assets/images/BuyRedirectImg2.png";
import BuyRedirectImg3 from "../../assets/images/BuyRedirectImg3.png";
import BuyRedirectImg4 from "../../assets/images/BuyRedirectImg4.png";
import BuyRedirectImg5 from "../../assets/images/BuyRedirectImg5.png";
import BuyRedirectImg6 from "../../assets/images/BuyRedirectImg6.png";
import BuyRedirectImg7 from "../../assets/images/BuyRedirectImg7.png";
import BuyRedirectImg8 from "../../assets/images/BuyRedirectImg8.png";
import BuyRedirectImg9 from "../../assets/images/BuyRedirectImg9.png";
import footerIcon1 from '../../assets/images/footerIcon1.png';
import footerIcon2 from '../../assets/images/footerIcon2.png';
import Filter from '../../assets/images/Filter.png';
import FilterVector from '../../assets/images/FilterVecor.png';
import ItemActivity1 from '../../assets/images/ItemActivity1.png';
import ItemActivity2 from '../../assets/images/ItemActivity2.png';
import ChartComponent from "../../components/ChartComponent/ChartComponent";
import Select from 'react-select';



function BuyBtnRedirectPage () {

    const [showDescription, setShowDescription] = useState(false)
    const onClickDescription = () => setShowDescription(!showDescription);

    const [showAbout, setShowAbout] =useState(false)
    const onClickAbout = () => setShowAbout(!showAbout);

    const [showDetails, setShowDetails] = useState(false)
    const onClickDetails = () => setShowDetails(!showDetails);

    const [showListings, setShowListings] = useState(false)
    const onClickListings = () => setShowListings(!showListings);

    const [showItemActivity, setShowItemActivity] = useState(false);
    const onClickItemActivity = () => setShowItemActivity(!showItemActivity);

    const [showCollection, setShowCollection] = useState(false)
    const onClickCollection= () => setShowCollection(!showCollection);

    const [showPrice, setShowPrice] = useState(false)
    const onClickShowPrice= () => setShowPrice(!showPrice);

    let colorOption = [
        { title: [{ItemActivity1}, 'Transfer'], id: 0 },
        {title: 'Minted', id: 0 }
    ];
    return (
        <>
            <div>
                <Header/>
                <main>
                    <div className={"redirectPage container-body"}>
                        <div className={"redirectPageBigDiv"}>
                            <div className={"redirectPageDivPart1"}>
                                <div className={"redirectPageDiv2"}></div>
                                <div className={"redirectPageDivs"}>
                                    <div className={"redirectPageDiv3 "} onClick={onClickDescription}>
                                        <div className={"redirectPageDiv3Part"}>
                                            <img className={"redirectPageDivIcon1"} src={BuyRedirectImg1} alt={"descriptionImg"}/>
                                            <div className={"redirectPageDiv4"}>Description</div>
                                        </div>
                                        {showDescription ?
                                        <img className={"redirectPageDivImg"} src={BuyUpVector} alt={"downVector"}/>:
                                            <img className={"redirectPageDivImg"} src={BuyDownVector} alt={"downVector"}/>  }
                                    </div>

                                    {showDescription &&
                                        <div className={"commonDiv"}>
                                            <div className={"DescriptionDiv1"}>Created by: <span className={"DescriptionDiv1Span"}>Bob Smith</span> </div>
                                            <div className={"DescriptionDiv2"}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. </div>
                                        </div>
                                    }
                                    <div className={"redirectPageDiv3"} onClick={onClickAbout}>
                                        <div className={"redirectPageDiv3Part"}>
                                            <img className={"redirectPageDivIcon2"} src={BuyRedirectImg2} alt={"aboutImg"}/>
                                            <div className={"redirectPageDiv4"}>About</div>
                                        </div>
                                        {showAbout?
                                        <img className={"redirectPageDivImg"} src={BuyUpVector} alt={"downVector"}/>:
                                            <img className={"redirectPageDivImg"} src={BuyDownVector} alt={"downVector"}/>  }
                                    </div>

                                    {showAbout &&
                                    <div className={"commonDiv "}>
                                        <div className={"aboutDiv1"}>
                                            <div className={"aboutDiv2"}></div>
                                            <div className={"aboutDiv3"}>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti.</div>
                                        </div>
                                        <div className={"aboutDiv4"}>
                                            <img className={"footerIcon1"} src={footerIcon1} alt={"footerIcon"}/>
                                            <div className='footerIcon2Div'>
                                                <img className={"footerIcon2"}  src={footerIcon2} alt={"footerIcon"}/>
                                            </div>
                                        </div>

                                    </div>
                                    }
                                    <div className={"redirectPageDiv3 radius "} onClick={onClickDetails}>
                                        <div className={"redirectPageDiv3Part"}>
                                            <img className={"redirectPageDivIcon3"} src={BuyRedirectImg3} alt={"detailsImg"}/>
                                            <div className={"redirectPageDiv4"}>Details</div>
                                        </div>
                                        {showDetails?
                                        <img className={"redirectPageDivImg"} src={BuyUpVector} alt={"downVector"}/>:
                                            <img className={"redirectPageDivImg"} src={BuyDownVector} alt={"downVector"}/>  }
                                    </div>
                                    {showDetails&&
                                    <div className={"commonDiv radius1"}>
                                        <div className={"detailsDiv1"}>
                                            <div className={"detailsDiv2 "}>Contract Address</div>
                                            <div className={"detailsDiv2  end blueOne"}>0x495f...7b5e</div>
                                        </div>
                                        <div className={"detailsDiv1"}>
                                            <div className={"detailsDiv2"}>Token ID</div>
                                            <div className={"detailsDiv2 end"}>2324922113504035</div>
                                        </div>
                                        <div className={"detailsDiv1"}>
                                            <div className={"detailsDiv2"}>Token Standard</div>
                                            <div className={"detailsDiv2 end"}>ERC-1155</div>
                                        </div>
                                        <div className={"detailsDiv1"}>
                                            <div className={"detailsDiv2"}>Blockchain</div>
                                            <div className={"detailsDiv2 end"}>Ethereum</div>
                                        </div>
                                        <div className={"detailsDiv1"}>
                                            <div className={"detailsDiv2"}>Metadata</div>
                                            <div className={"detailsDiv2 end"}>Centralized</div>
                                        </div>

                                    </div>
                                    }
                                </div>
                            </div>
                            <div className={"redirectPageDivPart2"}>
                                <div className={"redirectPageDivPart2Header"}>
                                    <div className={"redirectPageDivPart2Header1"}>Lorem ipsum dolor sit amet</div>
                                    <div className={"redirectPageDivPart2Header2"}>
                                        <img className={"redirectPageDivIcon4"} src={BuyRedirectImg4} alt={"icon"}/>
                                        <img className={"redirectPageDivIcon5"} src={BuyRedirectImg5} alt={"icon"}/>
                                    </div>
                                </div>
                                <div className={"redirectPageDiv5"}>Owned by: Bob Smith</div>
                                <div className={"redirectPageDiv6"}>Current Price</div>
                                <div className={"redirectPageDiv7"}>
                                    <div className={"redirectPageDiv8"}>*10 </div>
                                    <div className={"redirectPageDiv9"}>( $20)</div>
                                </div>
                                <div className={"redirectPageBtns"}>
                                    <div className={"redirectPageBtn1"}>Buy Now</div>
                                    <div className={"redirectPageBtn2"}>Make an offer PM</div>
                                </div>
                                <div className={"place"}>
                                    <div className={ "bigger"} onClick={onClickShowPrice}>
                                        <div className={"redirectPageDiv3Part"}>
                                            <img className={"redirectPageDivIcon6"} src={BuyRedirectImg6} alt={"icon"}/>
                                            <div className={"redirectPageDiv4"}>Price history</div>
                                        </div>
                                        {showPrice?
                                        <img className={"redirectPageDivImg"} src={BuyUpVector} alt={"downVector"}/>:
                                            <img className={"redirectPageDivImg"} src={BuyDownVector} alt={"downVector"}/>}
                                        </div>


                                    {showPrice &&
                                        <div className={"priceHistoryWholeDiv"}>
                                            <div className={"priceHistoryDiv1"}>
                                                <div className={"priceHistoryDiv2 btn"}>
                                                    <div className={"priceHistoryDiv3"}>Last 60 days</div>
                                                    <img className={"redirectPageDivImg"} src={BuyDownVector} alt={"downVector"}/>
                                                </div>
                                                <div className={"priceHistoryDiv4"}>
                                                    <div className={"priceHistoryDiv3"}>60 Day Avg. Price</div>
                                                    <div className={"priceHistoryDiv5"}>*10</div>
                                                </div>
                                                <div className={"priceHistoryDiv6"}>
                                                    <div className={"priceHistoryDiv3"}>60 Day Volume</div>
                                                    <div className={"priceHistoryDiv5"}>$20</div>
                                                </div>
                                            </div>
                                            <ChartComponent/>
                                        </div>
                                    }


                                </div>
                                <div className={"place"}>
                                    <div className={"redirectPageDiv3 bigger"} onClick={onClickListings}>
                                        <div className={"redirectPageDiv3Part"}>
                                            <img className={"redirectPageDivIcon7"} src={BuyRedirectImg7} alt={"icon"}/>
                                            <div className={"redirectPageDiv4"}>Listings</div>
                                        </div>
                                        {showListings ?
                                        <img className={"redirectPageDivImg"} src={BuyUpVector} alt={"downVector"}/>:
                                            <img className={"redirectPageDivImg"} src={BuyDownVector} alt={"downVector"}/>}
                                    </div>
                                    {showListings &&
                                        <div className={"showListingsWholeDiv"}>
                                            <div className={"showListings1"}>
                                                <div className={"showListings1Div"}>Unit Price</div>
                                                <div className={"showListings1Div"}>USD Unit Price</div>
                                                <div className={"showListings1Div"}>From</div>
                                            </div>
                                            <div className={"showListings2"}>
                                                <div className={"showListings2Part"}>
                                                    <div className={"showListings2Div"}>*10</div>
                                                    <div className={"showListings2Div"}>$20</div>
                                                    <div className={"showListings2Div blueColor"}>Bob Smith</div>
                                                </div>
                                                <div className={"showListings2Part"}>
                                                    <div className={"showListings2Div"}>*10</div>
                                                    <div className={"showListings2Div"}>$20</div>
                                                    <div className={"showListings2Div blueColor"}>Bob Smith</div>
                                                </div>
                                                <div className={"showListings2Part"}>
                                                    <div className={"showListings2Div"}>*10</div>
                                                    <div className={"showListings2Div"}>$20</div>
                                                    <div className={"showListings2Div blueColor"}>Bob Smith</div>
                                                </div>
                                                <div className={"showListings2Part"}>
                                                    <div className={"showListings2Div"}>*10</div>
                                                    <div className={"showListings2Div"}>$20</div>
                                                    <div className={"showListings2Div blueColor"}>Bob Smith</div>
                                                </div>
                                                <div className={"showListings2Part"}>
                                                    <div className={"showListings2Div"}>*10</div>
                                                    <div className={"showListings2Div"}>$20</div>
                                                    <div className={"showListings2Div blueColor"}>Bob Smith</div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className={"bigPlace"}>
                            <div className={"bigger biggest"} onClick={onClickItemActivity}>
                                <div className={"redirectPageDiv3Part"}>
                                    <img className={"redirectPageDivIcon8"} src={BuyRedirectImg8} alt={"icon"}/>
                                    <div className={"redirectPageDiv4"}>Item Activity</div>
                                </div>
                                {showItemActivity ?
                                <img className={"redirectPageDivImg"} src={BuyUpVector} alt={"downVector"}/>:
                                    <img className={"redirectPageDivImg"} src={BuyDownVector} alt={"downVector"}/>
                                }
                            </div>



                            {showItemActivity &&
                                <div className={"ItemActivityWhole"}>

                                    {/*<div className={"ItemActivityWholePart"}>*/}
                                    {/*    <div className={"ItemActivityFilterDiv"}>*/}
                                    {/*        <div className={"ItemActivityFilterDivPart"}>*/}
                                    {/*            <img className={"ItemActivityFilterImg"} src={Filter} alt={"ItemActivityFilterImg"}/>*/}
                                    {/*            <div className={"ItemActivityFilterDivPart"}>Filter</div>*/}
                                    {/*        </div>*/}
                                    {/*        <img className={"ItemActivityFilterVectorImg"} src={FilterVector} alt={"ItemActivityFilterVectorImg"}/>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                    <Select
                                        // defaultValue={[colorOption[0]]}
                                        isMulti
                                        name="colors"
                                        options={colorOption}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        getOptionLabel={(options)=> `${options.title}`}
                                    />



                                    <div className={"itemActivity1"}>
                                        <div className={"itemActivity2"}>Event</div>
                                        <div className={"itemActivity2"}>Price</div>
                                        <div className={"itemActivity2"}>From</div>
                                        <div className={"itemActivity2"}>To</div>
                                        <div className={"itemActivity2"}>Date</div>
                                    </div>
                                    <div className={"itemActivity3"}>
                                            <div className={"itemActivity4"}>
                                                <div className={"itemActivity5"}>
                                                    <img className={"ItemActivityStyle"}src={ItemActivity1} alt={"ItemActivity"}/>
                                                    <div className={"itemActivity6"}>Transfer</div>
                                                </div>
                                                <div className={"itemActivity7"}>X10</div>
                                                <div className={"itemActivity8"}>Wallet Address</div>
                                                <div className={"itemActivity9"}>Wallet Address</div>
                                                <div className={"itemActivity10"}>23 min ago</div>
                                            </div>


                                            <div className={"itemActivity4"}>
                                                <div className={"itemActivity5"}>
                                                    <img className={"ItemActivityStyle"} src={ItemActivity2} alt={"ItemActivity"}/>
                                                    <div className={"itemActivity6"}>Minted</div>
                                                </div>
                                                <div className={"itemActivity7"}>X10</div>
                                                <div className={"itemActivity8"}>Wallet Address</div>
                                                <div className={"itemActivity9"}>Wallet Address</div>
                                                <div className={"itemActivity10"}>23 min ago</div>
                                            </div>



                                            <div className={"itemActivity4"}>
                                                <div className={"itemActivity5"}>
                                                    <img className={"ItemActivityStyle"}src={ItemActivity1} alt={"ItemActivity"}/>
                                                    <div className={"itemActivity6"}>Transfer</div>
                                                </div>
                                                <div className={"itemActivity7"}>X10</div>
                                                <div className={"itemActivity8"}>Wallet Address</div>
                                                <div className={"itemActivity9"}>Wallet Address</div>
                                                <div className={"itemActivity10"}>23 min ago</div>
                                            </div>




                                            <div className={"itemActivity4"}>
                                                <div className={"itemActivity5"}>
                                                    <img className={"ItemActivityStyle"} src={ItemActivity2} alt={"ItemActivity"}/>
                                                    <div className={"itemActivity6"}>Minted</div>
                                                </div>
                                                <div className={"itemActivity7"}>X10</div>
                                                <div className={"itemActivity8"}>Wallet Address</div>
                                                <div className={"itemActivity9"}>Wallet Address</div>
                                                <div className={"itemActivity10"}>23 min ago</div>
                                            </div>
                                        </div>
                                </div>
                            }




                        </div>


                        <div className={"bigPlace"}>
                            <div className={"bigger biggest"} onClick={onClickCollection}>
                                <div className={"redirectPageDiv3Part"}>
                                    <img className={"redirectPageDivIcon9"} src={BuyRedirectImg9} alt={"icon"}/>
                                    <div className={"redirectPageDiv4"}>More from this collection</div>
                                </div>
                                {showCollection? <img className={"redirectPageDivImg"} src={BuyUpVector} alt={"downVector"}/>:
                                    <img className={"redirectPageDivImg"} src={BuyDownVector} alt={"downVector"}/>}
                            </div>
                            {showCollection &&
                            <div className={"collectionWholeDiv"}>
                                <div className={"collectionDiv"}></div>
                                <div className={"collectionDiv"}></div>
                                <div className={"collectionDiv"}></div>
                                <div className={"collectionDiv"}></div>
                            </div>

                            }
                        </div>
                    </div>
                </main>
                <div className={"redirectPageFooter"}>
                    <Footer/>
                </div>
            </div>
        </>
    )
}

export default BuyBtnRedirectPage;