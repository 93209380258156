import React, {useEffect, useRef, useState} from 'react';
import './style.css';
import Pinned from '../../assets/images/Pinned.png';
import Ellipse from '../../assets/images/Ellipse.png';
import Rectangle from '../../assets/images/Rectangle.png';
import NextSign from '../../assets/images/NextSign.png';
import DownVector from '../../assets/images/DownVector.png';
import AllImageUp from '../../assets/images/AllImageUp.png';
import Camera from '../../assets/images/Camera.svg';
import {useNavigate} from "react-router-dom";
import WhiteDownVector from '../../assets/images/WhiteDownVector.png';
import Header from '../../components/header/header';
import ButtonsContainer from '../../components/buttonsContainer/buttonsContainer';
import Footer from '../../components/footer/footer';
import ProfilePictureModal from "../../components/profilePictureModal/profilePictureModal";
import UpdateProfileModal from "../../components/updateProfileModal/updateProfileModal";
import axios from "axios";


function MyProfile() {

    const navigate = useNavigate();
  const handleRouteMyProfile = () => {
       navigate('/myProfile');
    }
    const [showMore, setShowMore] = useState(false)
    // const [selectedImage, setSelectedImage] = useState(null);
    // const [selectedCoverImage, setSelectedCoverImage] = useState(null);
    const [openAllDiv, setOpenAllDiv] = useState(false);
    const [showBlue, setShowBlue] = useState('');
    const [profilePictureModalIsOpen, setProfilePictureModal] = useState(false);
    const [updateProfileModalIsOpen, setUpdateProfileModal] = useState(false);

    const openAllDivFunction = () => {
        setOpenAllDiv (!openAllDiv);
    };



    const closeAllDiv = () => {
        setOpenAllDiv (false);
    };


    const openProfilePictureModal =()=>{
        setProfilePictureModal (true)
    }

    const closeProfilePictureModal =()=>{
        setProfilePictureModal (false)
    }

    const openUpdateProfileModal = () => {
        setUpdateProfileModal(true)
    }
    const closeUpdateProfileModal = () => {
        setUpdateProfileModal(false)
    }



    const handleShowMore = () => {
        setShowMore(!showMore)
    }


    const setBlue = (data) => {
        setShowBlue (data);
    };

    const ref = useRef()

    useEffect(() => {
        const checkIfClickedOutsideAllDropdown = e => {
            if (ref.current && !ref.current.contains(e.target)) {
                setOpenAllDiv(false)
            }
        }
        document.addEventListener("mousedown", checkIfClickedOutsideAllDropdown)
        return () => {
            // Cleanup the event listener
            document.removeEventListener("mousedown", checkIfClickedOutsideAllDropdown)
        }
    }, [openAllDiv])

    let getQrInfo = async () => {
        try {
            let response = await  axios.get("http://localhost:5000/wallet/check?")
            console.log(response.data);
            // console.log(response)
            if(!response.data.login){

                await getProfile();
                handleRouteMyProfile();
            }
        }catch (error){
            console.log(error.response, 'f');
        }
    }
    useEffect(()=>{
        setInterval(()=>{
            getQrInfo()
        },1000)
    },[])

    let getProfile =async ()=>{
        try {
            let response = await  axios.get("http://localhost:5000/wallet")
            // console.log(response.data , "getProfile");
            // window.open(response.data.qr_url, "_blank", 'noopener,noreferrer')
            // console.log(response);
        } catch (error){
            // console.log(error, 'f');
        }
    }





    return (
        <>
            <div>
                <Header/>
                <main>
                    <div className='profile'>
                        <div className='coverDiv'>
                            <img className='coverImg' src={Rectangle} alt="coverImage"/>
                            <img className='profileImg' src={Ellipse} alt="profileImg"  onClick={openProfilePictureModal}/>
                                    <img className='camImg' src={Camera} alt="cameraImg"  onClick={openProfilePictureModal}/>
                        </div>
                        <div className='myProfile container-body'>
                            <div className='profileName'>Bob Smith</div>
                            <div className='profileAddress'>Wallet Address</div>
                            <div className={"follow"}>
                                <div className={"followings "}>
                                    <div className={"followNum"}>24</div>
                                    <div className={"followWord"}>items</div>
                                </div>
                                <div className={"followings mediumOne"}>
                                    <div className={"followNum "}>116</div>
                                    <div className={"followWord"}>followers</div>
                                </div>
                                <div className={"followings"}>
                                    <div className={"followNum"}>98</div>
                                    <div className={"followWord"}>following</div>
                                </div>
                            </div>
                            <div className='profileDescription'>But I must explain to you how all this mistaken idea of
                                denouncing pleasure and praising pain was born and I will give you a complete account of
                                the system, and expound the actual teachings of the great explorer of the truth, the
                                master-builder of human happiness.
                            </div>
                            <div className='profileDate'>Joined Nov. 2021</div>
                            <div className='myProfileDiv'>
                                <div className={`myProfileDivs   ${showBlue === 'created' && "part"} `} onClick={() => {setBlue('created')
                                    closeAllDiv()}}>Created</div>
                                <div className={`myProfileDivs   ${showBlue === 'collected' ? "part" : ""} `} onClick={() => {setBlue('collected')
                                    closeAllDiv()}} >Collected</div>
                                <div className={`myProfileDivs lastPart   ${showBlue === 'all' ? "part" : ""} `} ref={ref} onClick={() => {
                                    setBlue('all')
                                    openAllDivFunction()
                                }} >
                                 All
                                    {openAllDiv &&
                                        <>
                                        <img className={"allPartVectorUp"} src={AllImageUp} alt={"allPartVector"}/>
                                    <div className={"dropdownAll"}>
                                        <ul>
                                            <li className="drop">Hidden</li>
                                            <li className="drop">Offers</li>
                                            <li className="drop">XPoem</li>
                                            <li className="drop">NFTs</li>
                                            <li className="drop">All</li>
                                        </ul>
                                    </div>
                                        </>
                                    }
                                    {showBlue ==='' &&    <img className={"allPartVector"} src={DownVector} alt={"allPartVector"}/>}
                                    {showBlue === 'all' && !openAllDiv &&
                                    <img className={"allPartVectorDownWhite"} src={WhiteDownVector} alt={"allPartVector"}/>}
                                    {showBlue === 'created' &&
                                        <img className={"allPartVector"} src={DownVector} alt={"allPartVector"}/>}
                                    {showBlue === 'collected' &&
                                        <img className={"allPartVector"} src={DownVector} alt={"allPartVector"}/>}
                                </div>
                            </div>
                            <div className='myProfileDiv2'>
                                <div className='myProfileDiv2Part'>
                                    <img className={"pinImg"} src={Pinned} alt="pinImage"/>Pinned to Profile
                                </div>
                            </div>

                            <div className='myProfileDiv3'>
                                <div className='myProfileDiv3Part1'>Title of NFT Link</div>
                                <div className='poemRows'>
                                    <img className="pinned2Img" src={Pinned} alt="PinnedImg"/>
                                    <div className='poemRowsTitle'>Sed ut perspiciatis unde</div>
                                    <div className='poemRowsContent'>But I must explain to you how all this,
                                        Mistaken idea of denouncing pleasure,
                                        And praising pain was born,
                                        I will give you a complete account ...
                                    </div>
                                    <div className='poemRowsPart' onClick={handleShowMore}>
                                        <div className='poemRowsPartDesc'>Description</div>
                                        <img className='poemRowsPartSign' src={NextSign} alt="signImage"/>
                                    </div>
                                    {showMore &&
                                        <div className={"expandedDescription"}>
                                            But I must explain to you how all this,
                                            Mistaken idea of denouncing pleasure,
                                            And praising pain was born,
                                            I will give you a complete account ...
                                        </div> }
                                </div>
                            </div>
                            <div className='myProfileDiv4'><ButtonsContainer/></div>
                        </div>
                    </div>
                    <ProfilePictureModal profilePictureModalIsOpen={profilePictureModalIsOpen} closeProfilePictureModal={closeProfilePictureModal} openUpdateProfileModal={openUpdateProfileModal}/>
                    <UpdateProfileModal updateProfileModalIsOpen={updateProfileModalIsOpen}  closeUpdateProfileModal={closeUpdateProfileModal} closeProfilePictureModal={closeProfilePictureModal}/>
                </main>
                <Footer/>
            </div>
        </>
    )
}

export default MyProfile;