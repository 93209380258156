import React, {useState} from 'react';
import './style.css';
import Likebtn from "../../assets/images/Likebtn.png";
import LikeBtnBlue from "../../assets/images/LikeBtnBlue.png";
import BlueIcon from "../../assets/images/BlueIcon.png";
import User from "../../assets/images/User.png";
import Replies from '../replies/replies'
import moment from "moment";


function Comments(props) {
    console.log(props);
    const [likeActiveComments, setLikeActiveComments] = useState(false);

    const [showReply, setShowReply] = useState(false);

    const [replyInput, setReplyInput] = useState("");

    const [showEdit, setShowEdit] = useState(false);

    const [replyEditIndex, setReplyEditIndex] = useState();

    const [editInput, setEditInput] = useState("");


    const handleShowReplyComment = () => {
        setShowReply(!showReply)
    }

    let addReplyCommentNft = (index) => {
        if (replyInput) {
            let data = {
                // id:index,
                photo: User,
                name: "Armine",
                date: moment().format('DD-MM-YY,  h:mm:ss a'),
                description: replyInput,
                like: ""
            }
            setReplyInput("");
            props.addingReplyNft(data, index);
        }
    }

    let _renderReply = () => {
        return props.item.reply.map((data, index) => {
            return (
                <Replies subItem={data} key={index} index={index} ondeleteCommentReplyData={ondeleteCommentReplyData}
                         openEdit={openEdit}/>
            )
        })
    }

    console.log(props.item.reply);

    let ondeleteCommentReplyData = (index) => {
        let array = props.item.reply;
        array.splice(index, 1);
        console.log(props.item.reply);
        props.deleteReplyComment(array);
    }


    const openEdit = (index) => {
        setReplyEditIndex(index);
        setEditInput(props.item.reply[index].description);
        setShowEdit(!showEdit);
    }



    return (
        <>
            <div className={"comments"}>
                <div className={"commentsDiv"}>
                    <div className={"TipButton btnSmall"}>TIP</div>
                    <img className="userPic" src={props.item.photo} alt={"userImage"}/>
                    <div className={"commentsBigDiv"}>
                        <div className={"commentsSmallDiv"}>
                            <div className={"commentsDivName"}>{props.item.name}</div>
                            <div className={"commentsDivDate"}>{props.item.date}</div>
                        </div>
                        <div className={"commentsDescription"}>{props.item.description}</div>

                        <div className={"likeReplyWholePart"}>
                            <div className={"likeReplyWholePart1"} onClick={() => {
                                props.ondeleteCommentData(props.index)
                            }}
                            >Delete
                            </div>
                            <div className={"likeReplyWholePart2"}>
                                <div className={"likeReplyWholePart2SmallDiv"}
                                     onClick={() => setLikeActiveComments(!likeActiveComments)}>
                                    {likeActiveComments ?
                                        <img className={"likeImgCommentsPartBlue"} src={LikeBtnBlue} alt={"likeImg"}/> :
                                        <img className={"likeImgCommentsPart"} src={Likebtn} alt={"likeImg"}/>}
                                    <div className={"likeReplyPart"}>Like</div>
                                </div>
                                <div className={"likeReplyPart reply"} onClick={() => {
                                    handleShowReplyComment(props.index)
                                }}>Reply
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {showReply &&
                    <div className={"replyingDiv"}>
                        <div className={"replyingDiv1"}>
                            <img className="userPicReply" src={props.item.photo} alt={"userImage"}/>
                            <div className={"replyingDiv2"}>{props.item.name}</div>
                        </div>
                        <div className='replyingDiv3'>
                        <textarea className={"replyingDiv4"} type="text" placeholder="Your comment"
                                  value={replyInput}
                                  onChange={(event) => setReplyInput(event.target.value)}
                        />
                            <img className={"showImgMyPageReply"} src={BlueIcon} alt="showImage" onClick={() => {
                                addReplyCommentNft(props.index, replyInput)
                                handleShowReplyComment()
                            }}/>
                        </div>
                    </div>
                }
                {showEdit &&
                    <div className={"replyingDiv"}>
                        <div className={"replyingDiv1"}>
                            <img className="userPicReply" src={props.item.reply[replyEditIndex].photo}
                                 alt={"userImage"}/>
                            <div className={"replyingDiv2"}>{props.item.reply[replyEditIndex].name}</div>
                        </div>
                        <div className='replyingDiv3'>
                        <textarea className={"replyingDiv4"} type="text" placeholder="Your comment"
                                  value={editInput}
                                  onChange={(e) => setEditInput(e.target.value)}
                        />
                            <img className={"showImgMyPageReply"} src={BlueIcon} alt="showImage"
                                 onClick={() => {
                                     props.replyEdit(props.index, replyEditIndex, editInput)
                                     setShowEdit(false)
                                 }}
                            />
                        </div>
                    </div>
                }
                {_renderReply()}
            </div>
        </>
    )
}

export default Comments;