import React from 'react';
import './style.css';
import CreateXpoemIcon from "../../assets/images/CreateXpoemIcon.png";
import NFTIcon from '../../assets/images/NFTIcon.png';




function CreateModal (props) {


    return (
        <>
            <div className={"popupBoxCreate"}>
                <div className={'popIconAndTextCreate'}  onClick={props.handleRouteNftPage}>
                    <img className={"NFTIconImg"} src={NFTIcon} alt={'NFTIconImage'}/>
                    <div className={"popText"}>NFT</div>
                </div>
                <div className={'popIconAndTextCreate'} onClick={props.handleRouteXpoemPage}>
                    <img className={"createXpoemIconImg"} src={CreateXpoemIcon} alt={'createXpoemIconImage'} />
                    <div className={"popText"}>XPoem</div>
                </div>
            </div>
        </>
    )
}

export default CreateModal;