import React, {useState} from 'react';
import './style.css';
import Modal from 'react-modal';
import NotificationUser from "../../assets/images/NotificationUser.png";
import SamHas from "../../assets/images/SamHas.png";
import Jessica from "../../assets/images/Jessica.png";
import UnreadIcon from "../../assets/images/UnreadIcon.png";
import NotificationsPart from "../notificationsPart/notificationsPart";

function Notifications (props){


    let [notificationData,setNotificationData] = useState([
        {
            notificationUser: NotificationUser,
            nameNot:"Andrew Smith",
            NotificationMessage: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiu...",
            notificationsDate:"12 hours ago",
            notificationUnread: UnreadIcon
        },
        {
            notificationUser: SamHas,
            nameNot:"Sam Hamswot",
            NotificationMessage: "Ut enim ad minim veniam, quis nostrud exercitation ullamco...",
            notificationsDate:"12 hours ago",
            notificationUnread: UnreadIcon
        },
        {
            notificationUser: Jessica,
            nameNot:"Jessica Jones ",
            NotificationMessage: "Duis aute irure dolor in reprehenderit in voluptate velit esse...",
            notificationsDate:"12 hours ago",
            notificationUnread: UnreadIcon
        },
        {
            notificationUser: SamHas,
            nameNot: "Lily Taylor",
            NotificationMessage: "Excepteur sint occaecat cupidatat non proident, sunt in culpa q...",
            notificationsDate:"12 hours ago",
            notificationUnread: UnreadIcon
        },
        {
            notificationUser: NotificationUser,
            nameNot:"George Evans",
            NotificationMessage: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium...",
            notificationsDate:"12 hours ago",
            notificationUnread: UnreadIcon
        },
        {
            notificationUser: Jessica,
            nameNot:"Liam Davies",
            NotificationMessage: " Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit ...",
            notificationsDate:"12 hours ago",
            notificationUnread: UnreadIcon
        },
    ]);

    const customStyles = {
        content: {
            padding: '20px 20px',
            maxWidth: '364px',
            width: '100%',
            height:'682px',
            top: '41%',
            right: '50%',
            left: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
        },
    };


    let renderNotificationsData = () => {
        return (
            notificationData.map((item, index) => {
                return (
                    <NotificationsPart item={item} key={index} />
                )
            })
        )
    }




return (
    <>
        <Modal
            isOpen={props.modalNotificationsIsOpen}
            onRequestClose={props.closeModalNotifications}
            // // onAfterOpen={props.closeSignUpModal}
            style={customStyles}
            ariaHideApp={false}
        >
    <div className={"allUnreadDiv"}>
        <div className={"allDiv"}>All</div>
        <div className={"unreadDiv"}>Unread</div>
    </div>
            {renderNotificationsData()}

    </Modal>
</>
)
}


export default Notifications;