import React from 'react';
import './style.css';
import footerIcon1 from '../../assets/images/footerIcon1.png';
import footerIcon2 from '../../assets/images/footerIcon2.png';
import XPoesyFooter from '../../assets/images/XPoesyFooter.png';

function Footer() {

     return (
         <>
         <div className={"footer"}>
            <div className={"footerWhole container-body"}>
                <div className={"footerPart"}>
                    <div className={"footers1"}>Join the Community</div>
                    <img className="footerIcon1" src={footerIcon1} alt="footerIcon1"/>
                    <div className='footerIcon2Div'>
                    <img className="footerIcon2" src={footerIcon2} alt="footerIcon2"/>
                    </div>
                </div>
                <div className={"footerPartMain"}>
                    <img className={"footerPart2 XPoesyFooterImg "} src={XPoesyFooter} alt={"XPoesyFooterImg"}/>
                    <div className={"footerPart2"}>My Account</div>
                    <div className={"footerPart2"}>Resources</div>
                    <div className={"footerPart2"}>Help Center</div>
                    <div className={"footerPart2 noMargin"}>Community</div>
                </div>
            </div>
        </div>
         </>
     )
}

export default Footer;