import React from 'react';
import './style.css';



function NotificationsPart (props){

    return (
        <>
            <div className={"notificationsDiv"}>
                <div className={"notificationsDiv1"}>
                    <img className={"UserPicNot"} src={props.item.notificationUser} alt={"UserPic"}/>
                    <div className={"notificationsDiv2"}>
                        <div className={"nameNot"}>{props.item.nameNot} <span className={"NotificationMessage"}>
                        {props.item.NotificationMessage}
                        </span></div>
                        <div className={"notificationsDate"}>{props.item.notificationsDate}</div>
                    </div>
                    <img className={"notificationUnread"} src={props.item.notificationUnread} alt={"unreadPic"}/>
                </div>
            </div>
        </>
    )
}


export default NotificationsPart;