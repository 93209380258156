import React, {useState} from 'react';
import './style.css';
import settingsSection1 from "../../assets/images/settingsSection1.png";
import settingsSection2 from "../../assets/images/settingsSection2.png";
import settingsSection3 from "../../assets/images/settingsSection3.png";
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";


function Settings () {


    const [showBlueSettings, setShowBlueSettings]= useState('');

    const setSettingsBlueActive =(data)=>{
        setShowBlueSettings(data);
    }


    return (
        <>
            <div>
                <Header/>
                <main>
            <div className={"settingAll container-body"}>
                <div className={"settingsPart1"}>
                    <div className={`settingsPart1Div1 ${showBlueSettings === 'general' ? "settingsBlue": ""} `} onClick={() => setSettingsBlueActive('general')} >
                        <img className={"settingsPart1Div1Icon "} src={settingsSection1} alt={"generalSettings"}/>
                        <div className={"settingsPart1Div1Description"} >General</div>
                    </div>

                    <div className={`settingsPart1Div1 ${showBlueSettings === 'notification' ? "settingsBlue": ""} `} onClick={() => setSettingsBlueActive('notification')} >
                        <img className={"settingsPart1Div1Icon"} src={settingsSection2} alt={"notificationSettings"}/>
                        <div className={"settingsPart1Div1Description"} >Notification settings</div>
                    </div>

                    <div className={`settingsPart1Div1 ${showBlueSettings === 'settings' ? "settingsBlue" : ""} `} onClick={() => setSettingsBlueActive('settings')} >
                        <img className={"settingsPart1Div1Icon"} src={settingsSection3} alt={"messageSettings"}/>
                        <div className={"settingsPart1Div1Description"} >Messages</div>
                    </div>
                </div>



                <div className={"settingsPart2"}>
                    <div className={"settingsPart2General"}>General settings</div>


                    <div className="userDetails">
                         <label htmlFor="userName" className="labelWord"> Username </label>
                                     <input className="userDetailsInput" type="text" placeholder="Enter username"
                                         id={'userName'} />
                              {/*       <div className="text-danger">*/}
                              {/*{errors.email}*/}
                              {/*  </div>*/}
                             </div>

                    <div className="userDetails">
                        <label htmlFor="emailAddress" className="labelWord"> Email address* </label>
                        <input className="userDetailsInput" type="text" placeholder="example@gmail.com"
                               id={'emailAddress'} />
                        {/*       <div className="text-danger">*/}
                        {/*{errors.email}*/}
                        {/*  </div>*/}
                    </div>
                    <div className="userDetails">
                        <label htmlFor="bio" className="labelWord"> Bio </label>
                        <textarea className="userDetailsInput bioDetails"  placeholder="Tell the world your story!"
                               id={'bio'} />
                        {/*       <div className="text-danger">*/}
                        {/*{errors.email}*/}
                        {/*  </div>*/}
                    </div>
                    <div className='cancelCreateSettings'>
                        <div className='cancelBtnSettings btn'>Cancel</div>
                        <div className='saveBtnSettings btn'>Save</div>
                    </div>
                </div>
            </div>

                </main>
                <Footer/>
            </div>
        </>
    )
}

export default Settings;