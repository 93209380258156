import React, {useState} from 'react';
import './style.css';
import Likebtn from "../../assets/images/Likebtn.png";
import LikeBtnBlue from "../../assets/images/LikeBtnBlue.png";






function Replies (props) {

    const [likeActiveComments, setLikeActiveComments]=useState(false);

    return (
        <>
            <div className={"repliesBox"}>
                <div className={"repliesDiv"}>
                    <img className="userPicReplyDiv" src={props.subItem.photo} alt={"userImage"}/>
                    <div className={"commentsBigDivReply"}>
                        <div className={"commentsSmallDivReply"}>
                            <div className={"commentsDivNameReply"}>{props.subItem.name}</div>
                            <div className={"commentsDivDateReply"}>{props.subItem.date}</div>
                        </div>
                        <div className={"commentsDescriptionReply"}>{props.subItem.description}</div>

                        <div className={"likeReplyWholePartReply"}>
                            <div className={"likeReplyWholeReply"}>
                                <div className={"likeReplyWholePart1Reply"} onClick={() => {
                                    props.ondeleteCommentReplyData(props.index)}}>Delete </div>
                                <div className={"likeReplyWholePart1Reply edit"} onClick={()=>{props.openEdit(props.index)}}>Edit </div>
                            </div>
                            <div className={"likeReplyWholePart2Reply"}>
                                <div className={"likeReplyWholePart2SmallDivReply"} onClick={()=>setLikeActiveComments(!likeActiveComments)}>
                                    {likeActiveComments? <img className={"likeImgCommentsPartBlue"} src={LikeBtnBlue} alt={"likeImg"}/>:
                                        <img className={"likeImgCommentsPart"} src={Likebtn} alt={"likeImg"}/>}
                                    <div className={"likeReplyPartReply"}>Like</div>
                                </div>
                                <div className={"likeReplyPart reply"}>Reply</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}

export default Replies;