import React, {useState} from 'react';
import './style.css';
import Likebtn from "../../assets/images/Likebtn.png";
import LikeBtnBlue from "../../assets/images/LikeBtnBlue.png";





function RepliesXpoem (props) {

    const [likeActiveCommentsXpoem, setLikeActiveCommentsXpoem]=useState(false);

    return (
        <>
            <div className={"repliesBox"}>
                <div className={"repliesDiv"}>
                    <img className="userPicReplyDiv" src={props.subItemXpoem.photoXpoem} alt={"userImage"}/>
                    <div className={"commentsBigDivReply"}>
                        <div className={"commentsSmallDivReply"}>
                            <div className={"commentsDivNameReply"}>{props.subItemXpoem.nameXpoem}</div>
                            <div className={"commentsDivDateReply"}>{props.subItemXpoem.dateXpoem}</div>
                        </div>
                        <div className={"commentsDescriptionReply"}>{props.subItemXpoem.descriptionXpoem}</div>

                        <div className={"likeReplyWholePartReply"}>
                            <div className={"likeReplyWholeReply"}>
                                <div className={"likeReplyWholePart1Reply"} onClick={() => {
                                    props.ondeleteCommentReplyDataXpoem(props.index)}}>Delete </div>
                                <div className={"likeReplyWholePart1Reply edit"} onClick ={()=>{props.openEditXpoem(props.index)}}>Edit </div>
                            </div>
                            <div className={"likeReplyWholePart2Reply"}>
                                <div className={"likeReplyWholePart2SmallDivReply"} onClick={()=>setLikeActiveCommentsXpoem(!likeActiveCommentsXpoem)}>
                                    {likeActiveCommentsXpoem? <img className={"likeImgCommentsPartBlue"} src={LikeBtnBlue} alt={"likeImg"}/>:
                                        <img className={"likeImgCommentsPart"} src={Likebtn} alt={"likeImg"}/>}
                                    <div className={"likeReplyPartReply"}>Like</div>
                                </div>
                                <div className={"likeReplyPart reply"}>Reply</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}

export default RepliesXpoem;