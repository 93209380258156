import React, {useEffect, useRef, useState} from 'react';
import './style.css';
import Search from "../../assets/images/Search.png";
import Person from "../../assets/images/Person.png";
import Bell from "../../assets/images/Bell.png";
import {useLocation} from 'react-router-dom';
import VectorHeader from "../../assets/images/VectorHeader.png";
import WalletIcon from "../../assets/images/WalletIcon.png";
import XPoesy from "../../assets/images/XPoesy.png";
import LogoImg from "../../assets/images/LogoImg.png";
import VectorUp from "../../assets/images/VectorUp.png";
import MessageIcon from "../../assets/images/MessageIcon.png";
import PopUp from "../popUp/popUp";
import CreateModal from "../createModal/createModal";

// import LoginModal from "../loginModal/loginModal";
import QrCode from "../qrCode/qrCode";
import {useNavigate} from "react-router-dom";
import XummWallet from "../xummWallet/xummWallet";
import LoadingModal from "../loadingModal/loadingModal";
import Notifications from "../notifications/notifications";


function Header() {


    const navigate = useNavigate();
    const handleRouteNftPage = () => {
        console.log("a");
        navigate('/createNft')
    }


    const handleRouteSettingsPage =() => {
        navigate('/settings');
        console.log("kkk");
    }

    const handleRouteXpoemPage = () => {
        navigate('/createXpoem');
    }

    let [isOpenPopUp, setIsOpenPopUp] = useState(false);
    let [isOpenPopUpCreate, setIsOpenPopUpCreate] = useState(false);
    // let [modalLoginIsOpen, setLoginIsOpen] = useState(false);
    let [modalQrIsOpen, setQrIsOpen]=useState(false);
    let [modalXummWalletIsOpen, setXummWalletIsOpen] = useState(false);
    let [modalNotificationsIsOpen, setModalNotifications] = useState(false);
    // let [modalLoadingIsOpen, setModalLoadingIsOpen]= useState(false);


    function openPopUp() {
        setIsOpenPopUp(!isOpenPopUp);
    }

    function openCloseModalCreate() {
        setIsOpenPopUpCreate(!isOpenPopUpCreate);
    }

    function openQrModal () {
        setQrIsOpen(true)
    }

    function closeQrModal (){
        setQrIsOpen(false)
    }

    // function openLoginModal() {
    //     setLoginIsOpen(true);
    // }
    //
    // function closeLoginModal() {
    //     setLoginIsOpen(false);
    // }

    function openXummWalletModal() {
        setXummWalletIsOpen(true);
    }

    function closeXummWalletModal() {
        setXummWalletIsOpen(false);
    }

    function openModalNotifications() {
        setModalNotifications(true);
    }

    function closeModalNotifications() {
        setModalNotifications(false);
    }

    // function openLoadingModal() {
    //     setModalLoadingIsOpen(true);
    // }
    //
    // function closeLoadingModal() {
    //     setModalLoadingIsOpen(false);
    // }

    const ref = useRef()
    let location = useLocation();



    useEffect(() => {
        const checkIfClickedOutsideCreateModal = e => {
            if (ref.current && !ref.current.contains(e.target)) {
                setIsOpenPopUpCreate(false)
            }
        }
        document.addEventListener("mousedown", checkIfClickedOutsideCreateModal)
        return () => {
            // Cleanup the event listener
            document.removeEventListener("mousedown", checkIfClickedOutsideCreateModal)
        }
    }, [isOpenPopUpCreate])


    useEffect(() => {
        const checkIfClickedOutsidePopUp = event => {
            if (ref.current && !ref.current.contains(event.target)) {
                setIsOpenPopUp(false)
            }
        }
        document.addEventListener("mousedown", checkIfClickedOutsidePopUp)
        return () => {
            // Cleanup the event listener
            document.removeEventListener("mousedown", checkIfClickedOutsidePopUp)
        }
    }, [isOpenPopUp])


    return (
        <>
            <div className={"header"}>
                <div className={"headerWhole container-header"}>
                    <div className={"headerPartOne"}>
                        <img className={"logoImage"} src={LogoImg} alt={"logoImg"}/>
                        <div className={"divForLogo"}>
                            <img className={"XpoesyDiv"} src={XPoesy} alt={"XPoesy"}/>
                        </div>

                        <div className={"headerSearchDiv"}>
                            <img className={"searchImg"} src={Search} alt="search"/>
                            <input className={"headerSearchInput"} type="text" placeholder="Search NFTs, XPoems "/>
                        </div>
                    </div>
                    {
                        (location.pathname === '/myProfile' || location.pathname === '/myPage' || location.pathname === '/myPageXpoem' || location.pathname === '/buyBtnRedirectPage') ?
                            <div className='headerPartSecond'>

                                <img className='personPhoto' src={Person} alt="personPhoto"
                                     ref={ref}   onClick={openPopUp}/>
                                {isOpenPopUp && <PopUp handleRouteNftPage={handleRouteNftPage} handleRouteXpoemPage={handleRouteXpoemPage} handleRouteSettingsPage={handleRouteSettingsPage}/>}
                                <img className={"messageImage"} src={MessageIcon} alt="messageImage"/>
                                <img className={"bellImage"} src={Bell} alt="bellImg" onClick={openModalNotifications}/>
                                <img className={"walletIconImg"} src={WalletIcon} alt={"WalletIcon"} onClick={openXummWalletModal}/>
                            </div> : <div className={"headerPartTwo"}>
                                <div className={"createHeaderPart"}  ref={ref} onClick={openCloseModalCreate}>Create
                                    <span className={"vectorHeaderPart"}>  {isOpenPopUpCreate ?<img src={VectorUp} alt="vectorImg"/> : <img src={VectorHeader} alt="vectorImg"/> } </span>
                                    {isOpenPopUpCreate && <CreateModal   handleRouteNftPage={handleRouteNftPage} handleRouteXpoemPage={handleRouteXpoemPage}/>}
                                </div>
                                <div className={"loginBtn btn"} onClick={openXummWalletModal}>Log in</div>
                                <img className={"walletIconImg"} src={WalletIcon} alt={"WalletIcon"} onClick={openXummWalletModal}/>
                            </div>
                    }
                </div>
            </div>
            <QrCode modalQrIsOpen={modalQrIsOpen} closeQrModal={closeQrModal} closeXummWalletModal={closeXummWalletModal}/>
            {/*<LoginModal modalLoginIsOpen={modalLoginIsOpen} closeLoginModal={closeLoginModal} closeXummWalletModal={closeXummWalletModal} />*/}
            <XummWallet modalXummWalletIsOpen={modalXummWalletIsOpen} closeXummWalletModal={closeXummWalletModal} openQrModal={openQrModal}/>
            <Notifications modalNotificationsIsOpen={modalNotificationsIsOpen} closeModalNotifications={closeModalNotifications}/>
            {/*<LoadingModal modalLoadingIsOpen={modalLoadingIsOpen} closeLoadingModal={closeLoadingModal} />*/}
        </>
    )
}

export default Header;
