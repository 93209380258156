import React, {useState} from 'react';
import './style.css';
import Modal from 'react-modal';
import ProfilePictureModalPic2 from "../../assets/images/ProfilePictureModalPic2.png";
import Cloud from "../../assets/images/Cloud.png";


function UpdateProfileModal (props){

    const [selectedImage, setSelectedImage] = useState(null);

    const customStyles = {
        content: {
            padding: '40px 40px',
            maxWidth: '600px',
            width: '100%',
            height:'512px',
            top: '50%',
            right: '63%',
            left: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
        },
    };

    return (
        <>
            <Modal
                isOpen={props.updateProfileModalIsOpen}
                onRequestClose={props.closeUpdateProfileModal}
                onAfterOpen={props.closeProfilePictureModal}
                style={customStyles}
                ariaHideApp={false}
            >

                <div className={"UpdateProfileModalDiv1"}>
                    <div className={"UpdateProfileModalDiv2"}>Change profile picture</div>
                    <img className={"UpdateProfileModalDiv3"} src={ProfilePictureModalPic2} alt={"closeButtonPic"} onClick={props.closeUpdateProfileModal}/>
                </div>

                <div className={"UpdateProfileModalLastDiv"}>

                        <label className="updateProfileLabelName" htmlFor="updateProfilePicLabel">
                            {selectedImage ?   <img className={"updateProfPic"} alt={"not found"} width={"520px"} src={URL.createObjectURL(selectedImage)} />:
                                <div className={"UpdateProfileModalLastDivPart"}>
                                     <img className="cloudImageDiv" src={Cloud} alt="cloudImg"/>
                            <div className='dropDivUpload'>Drop your image here.</div>
                            <div className='unploadDivPart'>Upload </div>
                                </div>}
                        </label>
                        <input className={"updatePicInput"} id={'updateProfilePicLabel'}
                               type="file"
                               name="myImageUploadProfilet"
                               onChange={(event) => {
                                   console.log(event.target.files[0]);
                                   setSelectedImage(event.target.files[0]);
                               }}
                        />       
                </div>
            </Modal>
        </>
    )
}


export default UpdateProfileModal;