import React, {useState} from 'react';
import './style.css';
import NextSign from "../../assets/images/NextSign.png";
import ButtonsContainer from '../buttonsContainer/buttonsContainer';
import PersonalInformation from "../personalInformation/personalInformation";
import {useNavigate} from "react-router-dom";

function PoetPage (props) {

    const navigate = useNavigate();
    const handleXpoemPage = () => {
        navigate('/MyPageXpoem')
    }

    return (
        <>
            <div className={"poetPageDiv"}>
                <PersonalInformation item={props.item}/>
                <div className={"poemTitle"}>{props.item.title}</div>
                <div className={"poemContent"}>{props.item.xpoem}</div>
                <div className={"readMoreDiv"}>
                    <div className={"readMore"} onClick={handleXpoemPage}>Read more</div>
                    <div className={"NextSignDiv"}>
                        <img src={NextSign} className={"NextSignImg"}  alt="next"/>
                    </div>
                </div>
                <ButtonsContainer/>
            </div>
        </>
    )
}

export default PoetPage;