import React, {useState, useEffect, useRef} from 'react';
import './style.css';
import Header from "../../components/header/header";
import PoetPage from "../../components/poetPage/poetPage";
import Vector from "../../assets/images/Vector.png";
import CategoriesUpVector from "../../assets/images/CategoriesUpVector.png";
import Footer from "../../components/footer/footer";
import axios from "axios";







function Home() {

    let [poemData,setPoemData] = useState([
            {
                name: "Bob Smith",
                title:"Sed ut perspiciatis unde",
                xpoem: "But I must explain to you how all this, Mistaken idea of denouncing pleasure, And praising pain was born, I will give you a complete account ..."
            },
            {
                name: "John Brown",
                title:"Temporibus autem quibusdam et",
                xpoem: "At vero eos et accusamus et iusto odio,\n" +
                    "Dignissimos ducimus qui blanditiis, \n" +
                    "Voluptatum deleniti atque corrupti quos,\n" +
                    "Quas molestias excepturi sint occaecati. "
            },

            {
                name: "Sam Hamswot",
                title:"Lorem ipsum dolor sit amet",
                xpoem: "Sed ut perspiciatis unde omnis iste natus, Voluptatem accusantium doloremque, Laudantium, totam rem aperiam, eaque, \n" +
                    "Quae ab illo inventore veritatis et."
            },
            {
                name:"Jessica Jones",
                title:"Sed ut perspiciatis unde",
                xpoem: "But I must explain to you how all this, Mistaken idea of denouncing pleasure, And praising pain was born, I will give you a complete account ..."
            },
            {
                name: "Lily Taylor",
                title:"Lorem ipsum dolor sit amet",
                xpoem: "At vero eos et accusamus et iusto odio,\n" +
                    "Dignissimos ducimus qui blanditiis, \n" +
                    "Voluptatum deleniti atque corrupti quos,\n" +
                    "Quas molestias excepturi sint occaecati. "
            },
            {
                name: "Sophie Davies",
                title:"Temporibus autem quibusdam et",
                xpoem: "Sed ut perspiciatis unde omnis iste natus, Voluptatem accusantium doloremque, Laudantium, totam rem aperiam, eaque, \n" +
                    "Quae ab illo inventore veritatis et."
            },
            {
                name: "Megan Johnson",
                title:"Sed ut perspiciatis unde",
                xpoem: "At vero eos et accusamus et iusto odio,\n" +
                    "Dignissimos ducimus qui blanditiis, \n" +
                    "Voluptatum deleniti atque corrupti quos,\n" +
                    "Quas molestias excepturi sint occaecati. "
            },
            {
                name: "Barbara Miller",
                title:"Lorem ipsum dolor sit amet",
                xpoem: "But I must explain to you how all this,\n" +
                    "Mistaken idea of denouncing pleasure,\n" +
                    "And praising pain was born,\n" +
                    "I will give you a complete account ..."
            },
            {
                name: "George Evans",
                title:"Sed ut perspiciatis unde",
                xpoem: "At vero eos et accusamus et iusto odio,\n" +
                    "Dignissimos ducimus qui blanditiis, \n" +
                    "Voluptatum deleniti atque corrupti quos,\n" +
                    "Quas molestias excepturi sint occaecati. "
            },
            {
                name: "Liam Davies",
                title:"Sed ut perspiciatis unde",
                xpoem: "But I must explain to you how all this, Mistaken idea of denouncing pleasure, And praising pain was born, I will give you a complete account ..."
            }
            ]);

    useEffect(()=> {
        axios
            .get("http://localhost:5000/xpoem")
            .then(response => {
                console.log(response, "getXpoem");
                setPoemData ([response.data[response.data.length-1], ...poemData]);
            })

            .catch(err => {
                console.log(err,  "getXpoemError")
            })
    },[])


    const ref = useRef()


    const [open,setOpen] = useState(false);
    const [showUnderlinedHomePageLinks, setShowUnderlinedHomePageLinks] = useState('Xpoems');



    const openDropdown = () => {
        setOpen(!open);
    };

    const setUnderlinedHomePageLinks= (data) => {
        setShowUnderlinedHomePageLinks(data)
    }

    useEffect(() => {
        const checkIfClickedOutside = e => {
            if (ref.current && !ref.current.contains(e.target)) {
                setOpen(false)
            }
        }
        document.addEventListener("mousedown", checkIfClickedOutside)
        return () => {
            // Cleanup the event listener
            document.removeEventListener("mousedown", checkIfClickedOutside)
        }
    }, [open])



    let renderPoemData = () => {
        return (
            poemData.map((item, index) => {
                return (
                    <PoetPage item={item} key={index} />
                )
            })
        )
    }


    const LoadMoreFunc=()=>{
        return(
            setPoemData+renderPoemData()
        )
    }



    return (
        <>
            <div >
                <Header />
                <main>
                    <div className={"homePage container-body"}>
                        <div className={"homeTitle"}>Poet’s Hall</div>
                        <div className={"homeMenuDiv"}>
                            <div className={`homeMenuDivPart  ${showUnderlinedHomePageLinks === 'Xpoems' && "active"} `} onClick={() => {setUnderlinedHomePageLinks('Xpoems')
                                    }}>
                            <span className={"XWord"}>X</span>Poems</div>
                            <div className={`homeMenuDivPart nfts ${showUnderlinedHomePageLinks === 'NFTs' && "active"} `} onClick={() => {setUnderlinedHomePageLinks('NFTs')
                            }}>NFTs</div>
                            <div className={`homeMenuDivPart  ${showUnderlinedHomePageLinks === 'All' && "active"} `} onClick={() => {setUnderlinedHomePageLinks('All')
                            }}>All</div>
                            <div  className={`homeMenuDivPart last ${showUnderlinedHomePageLinks === 'Categories' && "lastactive"}`}   ref={ref}
                                  onClick={() => {
                                      {setUnderlinedHomePageLinks('Categories')
                                          openDropdown()
                                  }}}> CategorieS
                            <span className={"vectorPart"} > {open? <img src={CategoriesUpVector} alt="vectorImg"/>: <img src={Vector} alt="vectorImg"/>}</span>
                                {open && (
                                    <div className="dropdown">
                                        <ul>
                                            <li >Love</li>
                                            <li >Ballad</li>
                                            <li >Acrostic</li>
                                            <li >Comedy</li>
                                            <li >Religious</li>
                                        </ul>
                                    </div>)}
                            </div>
                        </div>

                        <div className={"renderingPoems"}>
                        {renderPoemData()}
                        </div>
                        <div className={"btn loadMoreBtn"} onClick={LoadMoreFunc}>Load more</div>
                    </div>
                </main>
                <Footer/>
            </div>
        </>
    )
}

export default Home;
