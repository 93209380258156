import React, {useState} from 'react';
import './style.css';
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import DownVector from "../../assets/images/DownVector.png";
import Home from '../home/home';
import {useNavigate} from "react-router-dom";
import axios from "axios";
import qs from 'qs';



function CreateXpoem () {


    const navigate = useNavigate();
    const handleRouteXpoemPage = () => {
        navigate('/myPageXpoem');
    }

    const handleRouteHomePage= () => {
        navigate('/home');
    }

    const [values, setValues] = useState({
        title: '',
        category: '',
        author: '',
        nft_link: '',
        xpoem: ''
    });

    const [errors, setErrors] = useState({
        title: null,
        category: null,
        author:null,
        nft_link: null,
        xpoem: null
    });


    const handleChange = ({target: {name, value}}) => {
        setValues({
            ...values,
            [name]: value,
        });

        setErrors({  
            ...errors,
            [name]: null
        });
    };
    // let postXpoem = async (values) => {
    //     console.log(values , "values")
    //     try {
    //         let response = await axios.post("http://localhost:5000/xpoem/create",values)
    //         console.log(response,'createXpoem')
    //     } catch (e) {
    //         console.log(e, 'createXpoemError');
    //     }
    // }


    let postXpoem = async (values) => {
        console.log(values , "values")
        try {
            let response = await axios.post("http://localhost:5000/xpoem/create",qs.stringify(values),{
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    }
                })
            console.log(response,'createXpoem');
            setValues (response.data);
            handleRouteHomePage();

        } catch (e) {
            console.log(e.response, 'createXpoemError');
        }
    }


    const handleSubmit = () => {
        const {title, category, author, nft_link, xpoem} = values;
        let valid = true;
        let titleMessage = null;
        let categoryMessage = null;
        let authorMessage=null;
        let nft_linkMessage = null;
        let xpoemMessage=null;


        if (!title) {
            titleMessage = "Title is required";
            valid = false
        }


        if (!category) {
            categoryMessage = "Select a category!";
            valid = false
        }
        if (!author) {
            authorMessage = "Auther notes are required";
            valid = false
        }
        if (!nft_link){
            nft_linkMessage = "NftListingLink is required";
            valid = false
        }


        if (!xpoem) {
            xpoemMessage = "Your Xpoem is required";
            valid = false
        }

        setErrors({
            title: titleMessage,
            category: categoryMessage,
            author:authorMessage,
            nft_link:nft_linkMessage,
            xpoem: xpoemMessage
            });

        if (valid) {
            postXpoem(values);
        }
    }


    return (
        <>
            <div>
                <Header/>
                <main>
                    <div className={"createXpoemWholePage container-body"}>
                        <div className={"createXpoemPage"}>
                            <div className={"createXpoemPagePart1"}>
                                <div className='xpoemCreate'>Create XPoem</div>
                                <div className="nameNftDiv">
                                    <label  htmlFor="titleXpoem" className="wordDiv">Title*</label>
                                    <input className="nameNftInput" type="text" placeholder="Item title" name="title" value={values.title} onChange={handleChange}  id={'titleXpoem'} required/>
                                    <div className="text-danger-xpoem">
                                        {errors.title}
                                    </div>
                                </div>
                                <div className="nameNftDiv">
                                    <label  htmlFor="categoryXpoem" className="wordDiv">Category*</label>
                                    <select className="nameNftInput selCateg"  name="category"  onChange={handleChange} >
                                        <option  selected disabled hidden   value={values.category}>Select category</option>
                                        <option value="category1">category1</option>
                                        <option value="category2" >category2</option>
                                        <option  value="category3">category3</option>
                                        <option value="category4" >category4</option>
                                    </select>
                                    <div className="text-danger-xpoem">
                                        {errors.category}
                                    </div>
                                </div>
                                <div className="nameNftDiv">
                                    <label  htmlFor="authorNotesXpoem" className="wordDiv">Author Notes*</label>
                                    <textarea className="nameNftInput authorNotes" placeholder="Enter notes"  name="author" value={values.author} onChange={handleChange}  id={'authorNotesXpoem'} required/>
                                    <div className="text-danger-xpoem">
                                        {errors.author}
                                    </div>
                                </div>
                            </div>
                            <div className={"createXpoemPagePart2"}>
                                <div className="nameNftDiv">
                                    <label  htmlFor="nftListingXpoem" className="wordDiv">NFT Listing Link*</label>
                                    <input className="nameNftInput" type="text" placeholder="Enter link" name="nft_link" value={values.nft_link} onChange={handleChange}  id={'nftListingXpoem'} required/>
                                    <div className="text-danger-xpoem">
                                        {errors.nft_link}
                                    </div>
                                </div>
                                <div className="nameNftDiv">
                                    <label  htmlFor="yourXpoem" className="wordDiv">Your XPoem*</label>
                                    <textarea className="nameNftInput yourXpoems" placeholder="Enter notes"  name="xpoem" value={values.xpoem} onChange={handleChange}  id={'yourXpoem'} required/>
                                    <div className="text-danger-xpoem">
                                        {errors.xpoem}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='cancelCreateXpoem'>
                            <div className='cancelBtnXpoem btn' onClick={handleRouteHomePage}>Cancel</div>
                            <div className='CreateBtnXpoem btn' onClick={handleSubmit} >Create</div>
                        </div>
                    </div>
                </main>
                <Footer/>
            </div>
        </>
    )
}

export default CreateXpoem;
