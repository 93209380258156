import React from 'react';
import './style.css';
import UserPicture from "../../assets/images/UserPicture.png";

function PersonalInformation(props) {
    return (
        <>
            <div className={"personalInformationDiv1"}>
                <div className={"personalInformationDiv2"}>
                    <img className={"poetImg"} src={UserPicture} alt="userPic"/>
                </div>
                <div className={"personalInformationDiv3"}>
                    <div className={"personalInformationDiv4"}>Bob Smith</div>
                    <div className={"personalInformationDiv5"}>
                        <div className={"personalInformationDiv6"}>3 months ago </div>
                        <div className={"personalInformationDiv7"}>Follow</div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PersonalInformation;