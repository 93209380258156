import React from 'react';
import Home from "./app/pages/home/home";
import MyProfile from './app/pages/myProfile/myProfile';
import MyPage from "./app/pages/myPage/myPage";
import BuyBtnRedirectPage from "./app/pages/buyBtnRedirectPage/buyBtnRedirectPage";
import MyPageXpoem from "./app/pages/myPageXpoem/myPageXpoem";
import CreateXpoem from "./app/pages/createXpoem/createXpoem";
import CreateNft from "./app/pages/createNft/createNft";
import Settings from "./app/pages/settings/settings";
import {
  BrowserRouter,
    Routes,
    Route,
} from "react-router-dom";
import './App.css';





function App() {
  return (
    <BrowserRouter>
    <Routes>
    <Route exact path="/" element={<Home/>}/>
          <Route exact path="/home" element={<Home/>}/>
          <Route exact path="/myProfile" element={<MyProfile/>}/>
          <Route exact path="/myPage" element={<MyPage/>}/>
          <Route exact path="/MyPageXpoem" element={<MyPageXpoem/>}/>
          <Route exact path="/buyBtnRedirectPage" element={<BuyBtnRedirectPage/>}/>
          <Route exact path="/createNft" element={<CreateNft/>}/>
          <Route exact path="/createXpoem" element={<CreateXpoem/>}/>
          <Route exact path="/settings" element={<Settings/>}/>
          </Routes>
      </BrowserRouter>
  );
}

export default App;
