import React, {useState} from 'react';
import './style.css';
import Likebtn from "../../assets/images/Likebtn.png";
import LikeBtnBlue from "../../assets/images/LikeBtnBlue.png";
import BlueIcon from "../../assets/images/BlueIcon.png";
import User from "../../assets/images/User.png";
import moment from "moment";
import RepliesXpoem from "../repliesXpoem/repliesXpoem";


function CommentsXpoem (props) {

    const [likeActiveComments, setLikeActiveComments]=useState(false);
    const [showReplyXpoem, setShowReplyXpoem] = useState(false);
    const [replyInputXpoem, setReplyInputXpoem] = useState("");

    const [showEditXpoem, setShowEditXpoem] = useState(false);

    const [replyEditIndexXpoem, setReplyEditIndexXpoem] = useState();

    const [editInputXpoem, setEditInputXpoem] = useState("");


    const handleShowReplyCommentXpoem = () => {
        setShowReplyXpoem(!showReplyXpoem)
    }

    const addReplyCommentXpoem =(index) =>{
        if (replyInputXpoem) {
            let dataXpoem = {
                photoXpoem: User,
                nameXpoem: "Ani",
                dateXpoem: moment().format('DD-MM-YY,  h:mm:ss a'),
                description: replyInputXpoem,
                likeXpoem: ""
            }
            setReplyInputXpoem("");
            props.addingReplyXpoem(dataXpoem, index);
        }
    }

    console.log(props);


    let _renderReplyXpoem = () => {
        return props.item.replyXpoem.map((dataXpoem, index) => {
            return(
                <RepliesXpoem subItemXpoem={dataXpoem} key={index} index={index}  ondeleteCommentReplyDataXpoem={ondeleteCommentReplyDataXpoem}
                              openEditXpoem={openEditXpoem}/>
            )
        })
    }


    const ondeleteCommentReplyDataXpoem =(index)=>{
        let arrayXpoem =props.item.replyXpoem;
        arrayXpoem.splice(index,1);
        props.deleteReplyCommentXpoem(arrayXpoem);
    }


    const openEditXpoem = (index) => {
        setReplyEditIndexXpoem(index);
        setEditInputXpoem(props.item.replyXpoem[index].description);
        setShowEditXpoem(!showEditXpoem);
    }



    return (
        <>
            <div className={"commentsXpoem"}>
                <div className={"commentsDivXpoem"}>
                    <div className={"TipButtonXpoem btnSmall"}>TIP</div>
                    <img className="userPicXpoem" src={props.item.photoXpoem} alt={"userImage"}/>
                    <div className={"commentsBigDivXpoem"}>
                        <div className={"commentsSmallDivXpoem"}>
                            <div className={"commentsDivNameXpoem"}>{props.item.nameXpoem}</div>
                            <div className={"commentsDivDateXpoem"}>{props.item.dateXpoem}</div>
                        </div>
                        <div className={"commentsDescriptionXpoem"}>{props.item.description}</div>
                        <div className={"likeReplyWholePartXpoem"}>
                            <div className={"likeReplyWholePart1Xpoem"} onClick={()=>{props.ondeleteCommentsXpoem(props.index)}}
                            >Delete </div>
                            <div className={"likeReplyWholePart2Xpoem"}>
                                <div className={"likeReplyWholePart2SmallDivXpoem"} onClick={()=>setLikeActiveComments(!likeActiveComments)}>
                                    {likeActiveComments? <img className={"likeImgCommentsPartBlueXpoem"} src={LikeBtnBlue} alt={"likeImg"}/>:
                                        <img className={"likeImgCommentsPartXpoem"} src={Likebtn} alt={"likeImg"}/>}
                                    <div className={"likeReplyPartXpoem"}>Like</div>
                                </div>
                                <div className={"likeReplyPartXpoem replyXpoem"} onClick={()=>handleShowReplyCommentXpoem(props.index)}>Reply</div>
                            </div>
                        </div>
                    </div>
                </div>



                {showReplyXpoem &&
                    <div className={"replyingDiv"}>
                        <div className={"replyingDiv1"}>
                            <img className="userPicReply" src={props.item.photoXpoem} alt={"userImage"}/>
                            <div className={"replyingDiv2"}>{props.item.nameXpoem}</div>
                        </div>
                        <div className='replyingDiv3'>
                        <textarea className={"replyingDiv4"} type="text" placeholder="Your comment"
                                  value={replyInputXpoem}
                                  onChange={(event) => setReplyInputXpoem(event.target.value)}
                        />
                            <img className={"showImgMyPageReply"} src={BlueIcon} alt="showImage" onClick={() => {
                                addReplyCommentXpoem(props.index, replyInputXpoem)
                                handleShowReplyCommentXpoem ()
                            }}/>
                        </div>
                    </div>
                }


                {showEditXpoem &&
                    <div className={"replyingDiv"}>
                        <div className={"replyingDiv1"}>
                            <img className="userPicReply" src={props.item.replyXpoem[replyEditIndexXpoem].photoXpoem}
                                 alt={"userImage"}/>
                            <div className={"replyingDiv2"}>{props.item.replyXpoem[replyEditIndexXpoem].nameXpoem}</div>
                        </div>
                        <div className='replyingDiv3'>
                        <textarea className={"replyingDiv4"} type="text" placeholder="Your comment"
                                  value={editInputXpoem}
                                  onChange={(e) => setEditInputXpoem(e.target.value)}
                        />
                            <img className={"showImgMyPageReply"} src={BlueIcon} alt="showImage"
                                 onClick={() => {
                                     props.replyEditXpoem(props.index, replyEditIndexXpoem, editInputXpoem)
                                     setShowEditXpoem(false)
                                 }}
                            />
                        </div>
                    </div>
                }


                {_renderReplyXpoem()}
            </div>
        </>
    )
}

export default CommentsXpoem;