import React, {useState}  from "react";
import './style.css';
import BuyButton from "../../assets/images/BuyButton.png";
import LikeButton from "../../assets/images/LikeButton.png";
import BlueLikeBtn from "../../assets/images/BlueLikeBtn.png";
import BlueIcon from "../../assets/images/BlueIcon.png";
import User from "../../assets/images/User.png";
import moment from 'moment'
import {useLocation} from 'react-router-dom';
import axios from "axios";
import qs from "qs";



function BuyLikeComment (props) {


    let location = useLocation();

    const [likeActive, setLikeActive]=useState(false);
    const [likeActiveMouseOver, setLikeActiveMouseOver]=useState(false);
    const [newComment, setNewComment]=useState( '');
    const [newCommentXpoem, setNewCommentXpoem]=useState( '');


     let addCommentNft = () => {
         if(newComment){
         let data = {
             photo: User,
             name: "Armine",
             date: moment().format('DD-MM-YY,  h:mm:ss a'),
             //description: newComment,
             like: "",
             reply: []
         }
         setNewComment ("");
         props.changeComment(data);
         }
    }

    let addCommentXpoem = async ()=> {
        if (newCommentXpoem) {
            try {
                const dataXpoem = {
                    xpoem_id: "1",
                    user_id: "2",
                    description: newCommentXpoem
                }
                const response = await axios.post("http://localhost:5000/xpoem/comment", qs.stringify(dataXpoem), {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    }
                })
                console.log(response, 'NewComment');
                // addFunction(
                //
                // )
                setNewCommentXpoem("");
                props.changeCommentXpoem(dataXpoem);
            } catch (e) {
                console.log(e)
            }
        }
    }


    // let addCommentXpoem =()=>{
    //      if(newCommentXpoem){
    //          let dataXpoem={
    //              photoXpoem:User,
    //              nameXpoem: "Adam Smith",
    //              dateXpoem: moment().format('DD-MM-YY'),
    //              descriptionXpoem:newCommentXpoem,
    //              likeXpoem:"",
    //              replyXpoem: []
    //          }
    //          setNewCommentXpoem("");
    //          props.changeCommentXpoem(dataXpoem);
    //      }
    // }
            // const handleKeyDown = (event) => {
            //     if (event.key === "Enter") {
            //         event.stopPropagation();
            //         addCommentNft();
            //     };
            // };




    return (
        
        <>
            <div className={"myPageButtons"}>
                <div className={"buyNowBtnMyPage btnSmall"}>
                    <img className="buyButtonImgMyPage" src={BuyButton} alt="BuyButton"/> Buy Now
                </div>
                <div className={"likeBtnMyPage btnSmall"} onClick={()=>setLikeActive(!likeActive)}
                     onMouseOver={() =>setLikeActiveMouseOver(true)}
                     onMouseOut={() => setLikeActiveMouseOver(false)}>
                {likeActive || likeActiveMouseOver ?<img className={"blueLike"} src={BlueLikeBtn} alt="LikeButton"/>:
                    <img className="likeButtonImgMyPage" src={LikeButton} alt="LikeButton"/>}
                    Like
                </div>
                {location.pathname === '/myPage' &&
                    <div className='commentInputDivMyPage'>
                        <textarea className={"commentInputMyPage"} type="text" placeholder="Your comment"
                                  // onKeyPress={handleKeyDown}
                                  value={newComment} onChange={(e) => setNewComment(e.target.value)}/>
                        <img className={"showImgMyPage"} src={BlueIcon} alt="showImage" onClick={addCommentNft}/>
                    </div>
                }
                {location.pathname === '/myPageXpoem' &&
                    <div className='commentInputDivMyPage'>
                        <textarea className={"commentInputMyPage"} type="text" placeholder="Your comment"
                             value={newCommentXpoem}   onChange={(e)=>setNewCommentXpoem(e.target.value)} />
                        <img className={"showImgMyPage"} src={BlueIcon} alt="showImage" onClick={addCommentXpoem}/>
                    </div>
                }
            </div>
            </>
    )
}

export default BuyLikeComment;
