import React, {useState, UseEffect, useEffect} from 'react';
import './style.css';
import {useNavigate} from "react-router-dom";
import Close from '../../assets/images/Close.png';
import WalletImage from '../../assets/images/WalletImage.png';
import Modal from "react-modal";
import axios from 'axios';




function XummWallet (props) {

//
// useEffect(()=> {
//     get()
// },[])

    let get = async () => {
        try {
          let response = await  axios.get("http://localhost:5000/wallet/connect")
            console.log(response.data.qr_url);
            window.open(response.data.qr_url, "_blank", 'noopener,noreferrer')
            console.log(response);
        }catch (e){
            console.log(e, 'f');
        }
    }

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            padding: '0px 15px',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            maxWidth: '480px',
            width: '100%',
            overflowY: 'auto'
        },
    };


    return (

        <>
            <Modal
                isOpen={props.modalXummWalletIsOpen}
                onRequestClose={props.closeXummWalletModal}
                //onAfterOpen={props.closeXummWalletModal}
                style={customStyles}
                ariaHideApp={false}
            >
                <div className={"walletBox"}>
                    <div className={"walletDiv1"}>
                        <div className={"walletDiv2"}>Connect to your wallet</div>
                        <img className={"walletCloseImg"} src={Close} alt={"closeImg"} onClick={props.closeXummWalletModal}/>
                    </div>
                    <div className={"walletDiv3"}>To continue on XPoesy, log in to Xumm Wallet, your secure gateway to the blockchain.</div>
                    <div className={"walletDiv4"} onClick={get}>
                        <img className={"walletImg"} src={WalletImage} alt={"walletImg"}/>
                        <div className={"walletDiv5"}>Xumm Wallet</div>
                    </div>
                </div>
            </Modal>
        </>
    )
}


export default XummWallet;