import React, {useEffect, useState} from 'react';
import './style.css';
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import HeaderLinks from "../../components/headerLinks/headerLinks";
import Comments from "../../components/comments/comments";
import BuyLikeComment from "../../components/buyLikeComment/buyLikeComment";
import User from "../../assets/images/User.png";
import BlueIcon from "../../assets/images/BlueIcon.png";
import axios from "axios";


function MyPage() {


    let [commentsData, setCommentsData] = useState([
        {
            photo: User,
            name: "George Evans",
            date: "3 months ago",
            description: "Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?",
            like: "",
            reply: []
        },
        {
            photo: User,
            name: "Liam Davies",
            date: "3 months ago",
            description: "Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus, omnis voluptas assumenda est, omnis dolor repellendus.",
            like: "",
            reply: []
        },
        {
            photo: User,
            name: "Lily Taylor",
            date: "3 months ago",
            description: "At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi.",
            like: "",
            reply: []
        },
        {
            photo: User,
            name: "Barbara Miller",
            date: "3 months ago",
            description: "These cases are perfectly simple and easy to distinguish. In a free hour, when our power of choice is untrammelled and when nothing prevents our being able to do what we like best, every pleasure is to be welcomed and every pain avoided.",
            like: "",
            reply: []
        },
    ])

    useEffect(()=> {
        axios
            .get("http://localhost:5000/nft")
            .then(response => {

               // setState(response.data)
                console.log(response, "getNft");
                console.log(response.data);
                console.log(response.data[0].name);
            })
            .catch(err => {
                console.log(err,  "getNftError")
            })
    },[])  //sa anel home page -um ev setPoemData(response.data)

    let renderCommentData = () => {
        return (
            commentsData.map((item, index) => {

                return (
                    <>
                        <Comments item={item} key={index} index={index}  addingReplyNft={addingReplyNft} replyEdit={replyEdit}
                                  ondeleteCommentData={ondeleteCommentData} setCommentsData={setCommentsData} deleteReplyComment={deleteReplyComment}/>
                    </>
                )

            })
        )
    }


    const addingReplyNft = (data, index) => {
        let arr = commentsData;
        arr[index].reply.unshift(data);
    }


    const deleteReplyComment =(array)=>{
        setCommentsData([...array, ...commentsData]);
    }

    const changeComment = (data) => {
        setCommentsData([data, ...commentsData])
    }

    const ondeleteCommentData =(index) => {
        let arr=commentsData
        arr.splice(index,1)
        console.log(arr);
        setCommentsData([...arr]);
        //        setCommentsData([...[],...arr]);
    }


    const replyEdit = (commentIndex, replyIndex, inputValue) => {
        let arr = commentsData;
        arr[commentIndex].reply[replyIndex].description = inputValue;
        setCommentsData([...arr])
    }

    return (
        <>
            <div>
                <Header/>
                <main>
                    <div className={"myPage container-body"}>
                        <HeaderLinks config={"NFT"}/>
                        <div className={"myPageBigDiv"}></div>
                        <BuyLikeComment     changeComment={changeComment} commentsData={commentsData}/>
                        {renderCommentData()}
                    </div>
                </main>
                <Footer/>
            </div>
        </>
    )
}

export default MyPage;