import React, {useState, useEffect} from 'react';
import './style.css';
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import Cloud from "../../assets/images/Cloud.png";
import {useNavigate} from "react-router-dom";
import axios from 'axios';



function CreateNft() {

    const [nftImage, setNftImage] = useState(null);


    const navigate = useNavigate();
    const handleRouteNftPage = () => {
        navigate('/myPage');
    }

    const handleRouteHomePage = () => {
        navigate('/home');
    }

    const [values, setValues] = useState({
        name: '',
        collection: '',
        supply: '',
        category: '',
        memo: '',
        address: '',
        price: ''
    });

    const [errors, setErrors] = useState({
        name: null,
        collection: null,
        supply: null,
        category: null,
        memo: null,
        address: null,
        price: null
    });


    const handleChange = ({target: {name, value}}) => {
        setValues({
            ...values,
            [name]: value,
        });

        setErrors({
            ...errors,
            [name]: null
        });
    };


    // let post = async (values) => {
    //     try {
    //         let response = await axios.post("http://localhost:5000/nft/create", qs.stringify(values), {
    //             headers: {
    //                 'Content-Type': 'application/x-www-form-urlencoded'
    //             }
    //         })
    //         console.log(response, 'form')
    //     } catch (e) {
    //         console.log(e, 'aaaaaaaa');
    //     }
    // }

    let postNft = async (nftImage, values) => {
        try {
            let formData = new FormData()
            formData.append('myFile', nftImage)
            formData.append ('name', values.name)
            formData.append('collection', values.collection)
            formData.append('supply', values.supply)
            formData.append('category', values.category)
            formData.append('memo', values.memo)
            formData.append('address', values.address)
            formData.append('price', values.price)
            let response = await axios.post("http://localhost:5000/nft/create", formData)
            console.log(response, "createNft");
            handleRouteNftPage();
        } catch (error) {
            console.log(error, 'createNftError');
        }
    }


    const handleSubmit = async () => {
        const {name, collection, supply, category, memo, address, price} = values;
        let valid = true;
        let nameMessage = null;
        let collectionMessage = null;
        let supplyMessage = null;
        let categoryMessage = null;
        let memoMessage = null;
        let addressMessage = null;
        let priceMessage = null


        if (!name) {
            nameMessage = "Name is required";
            valid = false
        }

        if (!collection) {
            collectionMessage = "Collection is required";
            valid = false
        }

        if (!supply) {
            supplyMessage = "Supply is required";
            valid = false
        }
        if (!category) {
            categoryMessage = "Select a category";
            valid = false
        }

        if (!memo) {
            memoMessage = "Memo is required";
            valid = false
        }

        if (!address) {
            addressMessage = "Memo is required";
            valid = false
        }

        if (!price) {
            priceMessage = "Memo is required";
            valid = false
        }

        setErrors({
            name: nameMessage,
            collection: collectionMessage,
            supply: supplyMessage,
            category: categoryMessage,
            memo: memoMessage,
            address: addressMessage,
            price: priceMessage
        });

        if (valid) {
            await postNft(nftImage, values);  //sa minchev chani chanes handleRoutNftPage
        }
    }


    return (
        <>
            <div>
                <Header/>
                <main>
                    <div className={"createNftWholePage container-body"}>
                        <div className={"createNftPage"}>
                            <div className={"createNftPagePart1"}>
                                <div className='nftCreate'>Create NFT</div>
                                <div className="nameNftDiv">
                                    <label htmlFor="nameNft" className="wordDiv">Name*</label>
                                    <input className="nameNftInput" type="text" placeholder="Item name" id={'nameNft'}
                                           name="name" value={values.name} onChange={handleChange} required/>
                                    <div className="text-danger-nft">
                                        {errors.name}
                                    </div>
                                </div>
                                <div className="nameNftDiv">
                                    <label htmlFor="collectionNft" className="wordDiv">Collection*</label>
                                    <input className="nameNftInput" type="text" placeholder="Enter text"
                                           id={'collectionNft'} name="collection" value={values.collection}
                                           onChange={handleChange} required/>
                                    <div className="text-danger-nft">
                                        {errors.collection}
                                    </div>
                                </div>
                                <div className="nameNftDiv">
                                    <label htmlFor="supplyNft" className="wordDiv">Supply*</label>
                                    <input className="nameNftInput" type="text" placeholder="Enter text"
                                           id={'supplyNft'} name="supply" value={values.supply} onChange={handleChange}
                                           required/>
                                    <div className="text-danger-nft">
                                        {errors.supply}
                                    </div>
                                </div>
                                <div className="nameNftDiv">
                                    <label htmlFor="categoryNft" className="wordDiv">Category*</label>
                                    <select className="nameNftInput selCateg" name="category" onChange={handleChange}>
                                        <option selected={true} disabled hidden value={values.category}>Select
                                            category
                                        </option>
                                        <option className={"optionNameDiv"} value="Love">Love</option>
                                        <option className={"optionNameDiv"} value="Ballad">Ballad</option>
                                        <option className={"optionNameDiv"} value="Acrostic">Acrostic</option>
                                        <option className={"optionNameDiv"} value="Comedy">Comedy</option>
                                        <option className={"optionNameDiv"} value="Religious">Religious</option>
                                    </select>
                                    <div className="text-danger-nft">
                                        {errors.category}
                                    </div>
                                </div>
                                <div className="nameNftDiv">
                                    <label htmlFor="memoNft" className="wordDiv">MEMO*</label>
                                    <textarea className="nameNftInput memo" placeholder="Enter text" name="memo"
                                              value={values.memo} id={'memoNft'} onChange={handleChange}
                                              required/>
                                    <div className="text-danger-nft">
                                        {errors.memo}
                                    </div>

                                </div>


                                <div className="nameNftDiv">
                                    <label htmlFor="addressNft" className="wordDiv">Address*</label>
                                    <input className="nameNftInput" type="text" placeholder="Enter text"
                                           id={'addressNft'} name="address" value={values.address}
                                           onChange={handleChange} required/>
                                    <div className="text-danger-nft">
                                        {errors.address}
                                    </div>
                                </div>


                                <div className="nameNftDiv">
                                    <label htmlFor="priceNft" className="wordDiv">Price*</label>
                                    <input className="nameNftInput" type="text" placeholder="Enter text" id={'priceNft'}
                                           name="price" value={values.price} onChange={handleChange} required/>
                                    <div className="text-danger-nft">
                                        {errors.price}
                                    </div>
                                </div>


                            </div>
                            <div className={"createNftPagePart2 activePart"}>
                                <label className="nftLabelName" htmlFor="nftPicLabel">
                                    {nftImage ? <img className={"uploadedImageNft"} alt={"not found"} width={"393px"}
                                                     src={URL.createObjectURL(nftImage)}/> :
                                        <div className={"createNftPagePart2Div"}>
                                            <img className="cloudImage" src={Cloud} alt="cloudImg"/>
                                            <div className='dropDiv'>Drop your image here.</div>
                                            <div className='unploadDiv'>Upload</div>
                                        </div>}
                                </label>
                                <input className={"nftPicInput"} id={'nftPicLabel'}
                                       type="file"
                                       name="file"
                                       onChange={(event) => {
                                           console.log(event.target.files);
                                           setNftImage(event.target.files[0]);
                                           // postPictureNft(event.target.files[0])
                                       }}
                                />
                            </div>
                        </div>

                        <div className='cancelCreateNft'>
                            <div className='cancelBtnNft btn' onClick={handleRouteHomePage}>Cancel</div>
                            <div className='createBtnNft btn' onClick={handleSubmit}>Create</div>
                        </div>
                    </div>
                </main>
                <Footer/>
            </div>
        </>
    )
}

export default CreateNft;
