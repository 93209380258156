import React, {useEffect, useState} from 'react';
import './style.css';
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import HeaderLinks from "../../components/headerLinks/headerLinks";
import PersonalInformation from "../../components/personalInformation/personalInformation";
import CommentsXpoem from "../../components/commentsXpoem/commentsXpoem";
import User from "../../assets/images/User.png";
import BuyLikeComment from "../../components/buyLikeComment/buyLikeComment";
import axios from "axios";
import qs from "qs";


function MyPageXpoem() {


    let [commentsDataXpoem, setCommentsDataXpoem] = useState([
        {
            photoXpoem: User,
            nameXpoem: "George Evans",
            dateXpoem: "3 months ago",
            description: "Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?",
            likeXpoem: "",
            replyXpoem:[]
        },
        {
            photoXpoem: User,
            nameXpoem: "Liam Davies",
            dateXpoem: "3 months ago",
            description: "Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus, omnis voluptas assumenda est, omnis dolor repellendus.",
            likeXpoem: "",
            replyXpoem:[]
        },
        {
            photoXpoem: User,
            nameXpoem: "Lily Taylor",
            dateXpoem: "3 months ago",
            description: "At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi.",
            likeXpoem: "",
            replyXpoem:[]
        },
        {
            photoXpoem: User,
            nameXpoem: "Barbara Miller",
            dateXpoem: "3 months ago",
            description: "These cases are perfectly simple and easy to distinguish. In a free hour, when our power of choice is untrammelled and when nothing prevents our being able to do what we like best, every pleasure is to be welcomed and every pain avoided.",
            likeXpoem: "",
            replyXpoem:[]
        },
    ])


    // let postXpoemComment = async (commentsDataXpoem) => {
    //     console.log(commentsDataXpoem , "commentsDataXpoem")
    //     try {
    //         let response = await axios.post("http://localhost:5000/xpoem/comment",qs.stringify(commentsDataXpoem),{
    //             headers: {
    //                 'Content-Type': 'application/x-www-form-urlencoded'
    //             }
    //         })
    //         console.log(response,'createXpoemComments')
    //     } catch (e) {
    //         console.log(e, 'createXpoemCommentsError');
    //     }
    // }




    let renderCommentDataXpoem= () => {
        return (
            commentsDataXpoem.map((item, index) => {
                return (
                    <CommentsXpoem item={item} key={index} index={index} ondeleteCommentsXpoem={ondeleteCommentsXpoem}
                                   addingReplyXpoem={addingReplyXpoem} deleteReplyCommentXpoem={deleteReplyCommentXpoem} replyEditXpoem={replyEditXpoem}
                    />
                )
            })
        )
    }


    const changeCommentXpoem = (dataXpoem) => {
        setCommentsDataXpoem([dataXpoem, ...commentsDataXpoem])
    }

    const ondeleteCommentsXpoem =(index)=>{
        let newArr=commentsDataXpoem;
        newArr.splice(index, 1);
        setCommentsDataXpoem([...[],...newArr]);
    }

    const deleteReplyCommentXpoem =(arrayXpoem)=>{
        setCommentsDataXpoem([...arrayXpoem, ...commentsDataXpoem])
    }



    const addingReplyXpoem = (dataXpoem, index) => {
        let arrXpoem = commentsDataXpoem;
        arrXpoem[index].replyXpoem.unshift(dataXpoem);
        console.log(arrXpoem);
    }

    const replyEditXpoem = (commentIndexXpoem, replyIndexXpoem, inputValueXpoem) => {
        let array = commentsDataXpoem;
        array[commentIndexXpoem].replyXpoem[replyIndexXpoem].description = inputValueXpoem;
        setCommentsDataXpoem([...array])
    }

    return (
        <>
            <div>
                <Header/>
                <main>
                    <div className={"myPageXpoem container-body"}>
                        <HeaderLinks config={"Xpoem"}/>
                        <div className={"MyPageXpoemDiv1"}>
                            <PersonalInformation />
                        </div>
                        <div className={"MyPageXpoemDiv2"}>Sed ut perspiciatis unde</div>
                        <div className={"MyPageXpoemDiv3"}>But I must explain to you how all this,
                            Mistaken idea of denouncing pleasure,
                            And praising pain was born,
                            I will give you a complete account ...</div>
                        <div className={"MyPageXpoemDiv3"}>Sed ut perspiciatis unde omnis iste natus, Voluptatem accusantium doloremque, Laudantium, totam rem aperiam, eaque,
                            Quae ab illo inventore veritatis et.</div>
                        <div className={"MyPageXpoemDiv3"}>At vero eos et accusamus et iusto odio,
                            Dignissimos ducimus qui blanditiis,
                            Voluptatum deleniti atque corrupti quos,
                            Quas molestias excepturi sint occaecati.</div>
                        <div className={"MyPageXpoemDiv4"}>
                            <BuyLikeComment changeCommentXpoem={changeCommentXpoem} commentsDataXpoem={commentsDataXpoem}/>
                        </div>
                        <div className={"MyPageXpoemDiv5"}>
                            {renderCommentDataXpoem()}
                        </div>
                        </div>
                </main>
                <Footer/>
            </div>
        </>
    )
}

export default MyPageXpoem;


