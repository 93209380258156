import React, {useState} from 'react';
import './style.css';
import {useLocation} from 'react-router-dom';

function HeaderLinks(props) {

    const [showUnderlined, setShowUnderlined] = useState(props.config);
    let location = useLocation();

    const setUnderlined= (data) => {
        setShowUnderlined (data);
    };

    return (
        <>
            <div className={"myPageSmallDiv"}>
            {location.pathname === '/myPage' && <div className={`myPageSmallDivPart2   ${showUnderlined === 'NFT' && "activeUnderlined"} `} onClick={() => {setUnderlined('NFT')
                                    }} >NFT</div>}
                {location.pathname === '/myPageXpoem' && <div className={`myPageSmallDivPart2  ${showUnderlined === 'Xpoem' && "activeUnderlined"} `} onClick={() => {setUnderlined('Xpoem')
                                    }}>
                    <span className={"XWordHeader"}>X</span>Poems</div> }
                <div className={`myPageSmallDivPart2 ${showUnderlined === 'create' && "activeUnderlined"} `} onClick={() => {setUnderlined('create')
                                    }}>Create</div>
                <div className={`myPageSmallDivPart2 ${showUnderlined === 'explore' && "activeUnderlined"} `} onClick={() => {setUnderlined('explore')
                }}>Explore</div>
            </div>
        </>
    )
}

export default HeaderLinks;