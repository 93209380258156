import React, {useRef, useState} from 'react';
import './style.css';
import Chart from 'chart.js/auto';
import { getRelativePosition } from 'chart.js/helpers';

const useCanvas = (callback) => {
    const canvasRef = React.useRef(null);

    React.useEffect(() => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        callback([canvas, ctx]);
    }, []);

    return canvasRef;
}
function ChartComponent() {
    const canvasRef = useCanvas(([canvas, ctx]) => {
        const chart = new Chart(ctx, {
            type: 'line',
            data: {
                labels: [ "12/22", "12/24", "12/26", "12/28", "12/30", "1/1", "1/3"],
                datasets: [{
                    fill: false,
                    lineTension: 0.2,
                    // pointBorderColor: '#111',
                    // pointBackgroundColor: '#ff4000',
                    // pointBorderWidth: 2,
                    backgroundColor: 'rgba(52, 152, 219, 0.75)',
                    data: [
                        0,1,2
                    ],
                    tension: 1
                }]
            }
        });
    });



    return (
        <>
            <canvas ref={canvasRef} className={"chartCanvas"}></canvas>

        </>
    )
}

export default ChartComponent;