import React, {useState} from 'react';
import './style.css';
import QrIcon from "../../assets/images/QrIcon.png";
import QrScan from "../../assets/images/QrScan.png";
import Modal from "react-modal";
import axios from 'axios';


function QrCode (props) {




    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            padding: '40px',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            maxWidth: '420px',
            width: '100%',
            overflowY: 'auto'
        },
    };

    return (

        <>
            <Modal
                isOpen={props.modalQrIsOpen}
                onRequestClose={props.closeQrModal}
                onAfterOpen={props.closeXummWalletModal}
                style={customStyles}
                ariaHideApp={false}

            >
                <div className={"qrBox"}>
                    <div className={"qrDiv1"}>
                        <div className={"qrDiv2"}>Xumm Wallet</div>
                        <img className={"qrIconImg"} src={QrIcon} alt={"qrIconImg"}/>
                    </div>
                    <div className={"qrDiv3"}>Scan the QR code or click on the link to go to your Xumm Wallet  </div>
                    <div className={"qrDiv4"}>
                        <img className={"qrScanImg"} src={QrScan} alt={"qrScanImg"}/>
                    </div>
                </div>
            </Modal>

        </>
    )
}


export default QrCode;