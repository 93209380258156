import React, {useState} from 'react';
import './style.css';
import BuyButton from "../../assets/images/BuyButton.png";
import LikeButton from "../../assets/images/LikeButton.png";
import BlueLikeBtn from "../../assets/images/BlueLikeBtn.png";
import NumberButton from "../../assets/images/NumberButton.png";
import BlueIcon from "../../assets/images/BlueIcon.png";
import TipModal from "../TipModal/TipModal";


function ButtonsContainer() {

    let [likeActBtnCont, setLikeActBtnCont]=useState(false);
    let [tipModalIsOpen, setTipModalIsOpen] = useState(false);

    function openTipModal() {
        setTipModalIsOpen(true)
    }

    function closeTipModal() {
        setTipModalIsOpen(false)
    }

    return (
        <>
            <div className={"buttonsCont"}>
                <div className={"buttonsWholeDiv"}>
                    <div className={"BuyNowBtn btnSmall"}>
                        <img className="BuyButtonImg" src={BuyButton} alt="BuyButton"/> Buy Now
                    </div>
                    <div className={`likeBtn btnSmall ${likeActBtnCont ? 'clicked' : ''}`} onClick={()=>setLikeActBtnCont(!likeActBtnCont)}
                       >
                        <img className={"blueLikeContainer"} src={BlueLikeBtn} alt="LikeButton"/>
                        <img className="LikeButtonImg" src={LikeButton} alt="LikeButton"/>
                        Like
                    </div>
                    <div className='commentInputDiv'>
                        <input className={"commentInput"} type="text" placeholder="Your comment"/>
                        <img className={"showImg"} src={BlueIcon} alt="showImage"/>
                    </div>
                    <div className={"numberBtn btnSmall"}>
                        <img className="NumberButtonImg" src={NumberButton} alt="NumberButton"/>123
                    </div>
                    <div className={"TipButton btnSmall"} onClick={openTipModal}>TIP</div>
                </div>
            </div>
            <TipModal tipModalIsOpen={tipModalIsOpen} closeTipModal={closeTipModal}/>
        </>
    )
}

export default ButtonsContainer;