import React from 'react';
import './style.css';
import PopUpPhoto from '../../assets/images/PopUpPhoto.png';
import CreateXpoemIcon from '../../assets/images/CreateXpoemIcon.png';
import NFTIcon from '../../assets/images/NFTIcon.png';
import Settings from '../../assets/images/Settings.png';
import LogOut from '../../assets/images/LogOut.png';
import Camera from '../../assets/images/Camera.svg';


function PopUp (props) {


    return (
        <>
            <div className={"popupBox"}>
                <div className={"popUpPhotos"}>
                    <img className={"PopUpImg"} src={PopUpPhoto} alt={"profilePicture"}/>
                    <img className={"CameraImg"}  src={Camera} alt={"cameraPicture"}/>
                </div>
                <div className={"namePop"}>Bob Smith</div>
                     <div className={'popIconAndText'} onClick={props.handleRouteXpoemPage}>
                         <img className={"createXpoemIconImg"} src={CreateXpoemIcon} alt={'createXpoemIconImage'} />
                         <div className={"popText"}>Create XPoem</div>
                     </div>
                <div className={'popIconAndText'} onClick={props.handleRouteNftPage} >
                    <img className={"NFTImg"} src={NFTIcon} alt={'NFTImage'} />
                    <div className={"popText"}>Create NFT</div>
                </div>
                <div className={'popIconAndText'} onClick={props.handleRouteSettingsPage}>
                    <img className={"SettingsImg"} src={Settings} alt={'SettingsImage'}/>
                    <div className={"popText"}>Settings</div>
                </div>
                <div className={'popIconAndText'}>
                    <img className={"LogOutImg"} src={LogOut} alt={'LogOutImage'}/>
                    <div className={"popText"}>Log out</div>
                </div>
            </div>

        </>
    )
}

export default PopUp;